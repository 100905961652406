<template>
  <b-row class="container">
    <div
      class="items"
      data-aos="fade-right"
      data-aos-delay="500"
      data-aos-easing="ease-in-sine"
      style="background-color: #0070a8;"
    >
      <div class="icon-wrapper">
        <font-awesome-icon
          class="i"
          icon="book"
        />
      </div>
      <div class="project-name">
        <router-link to="/business-and-tech">
          <p>{{ $t('cards.card1') }}</p>
        </router-link>
      </div>
      <div class="info">
        <router-link
          style="text-decoration: none; color: black;"
          to="/business-and-tech"
        >
          <h3 class="project-name">
            {{ $t('cards.info') }}
          </h3>
        </router-link>
      </div>
    </div>
    <div
      class="items"
      data-aos="fade-right"
      data-aos-delay="500"
      style="background-color: #3BBADD;"
    >
      <div class="icon-wrapper">
        <font-awesome-icon
          class="i"
          icon="briefcase"
        />
      </div>
      <div class="project-name">
        <router-link to="/economia-digital">
          <p>{{ $t('cards.card2') }}</p>
        </router-link>
      </div>
      <div class="info">
        <router-link
          style="text-decoration: none; color: black;"
          to="/economia-digital"
        >
          <h3 class="project-name">
            {{ $t('cards.info') }}
          </h3>
        </router-link>
      </div>
    </div>
    <div
      class="items"
      data-aos="fade-left"
      data-aos-delay="500"
      style="background-color: #007e7d;"
    >
      <div class="icon-wrapper">
        <font-awesome-icon
          class="i"
          icon="graduation-cap"
        />
      </div>
      <div class="project-name">
        <a
          href="https://clubejecutivo.websincoste.com/"
          target="_blank"
        >
          <p>{{ $t('cards.card3') }}</p>
        </a>
      </div>
      <div class="info">
        <a
          style="text-decoration: none; color: black;"
          href="https://clubejecutivo.websincoste.com/"
          target="_blank"
        >
          <h3 class="project-name">{{ $t('cards.info') }}</h3>
        </a>
      </div>
    </div>
    <div
      class="items"
      data-aos="fade-left"
      data-aos-delay="500"
      data-aos-easing="ease-in-sine"
      style="background-color: #174a5f;"
    >
      <div class="icon-wrapper">
        <font-awesome-icon
          class="i"
          icon="euro-sign"
        />
      </div>
      <div class="project-name">
        <a href="/business-break">
          <p>{{ $t('cards.card4') }}</p>
        </a>
      </div>
      <div class="info">
        <a
          style="text-decoration: none; color: black;"
          href="/business-break"
        >
          <h3 class="project-name">
            {{ $t('cards.info') }}
          </h3>
        </a>
      </div>
    </div>
  </b-row>
</template>

<script>
export default {
    name: 'HomeCards'
};
</script>

<style scoped lang="scss">
$pri: #009090;
$sec: #e5e6f1;

body {
    background-color: $pri;
    box-sizing: border-box;
    font-family: 'Raleway', sans-serif;
}

.info {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 0px;
    height: 30px;
    width: 100%;
    margin-bottom: 25px;

    &:hover {
        background-color: rgba(280, 280, 280, 0.2);
    }
}

.container {
    width: auto;
    max-width: 1100px;
    text-align: center;

    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    box-sizing: border-box;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 0.5fr));
    grid-template-rows: 2fr;
    grid-gap: 5px;
}

@media only screen and (max-width: 380px) {
    .container {
        margin-left: -20px;
    }
}

.items {
    display: grid;
    grid-template-rows: 2fr 1fr;
    grid-gap: 0px;
    cursor: pointer;
    transition: all 0.6s;
    width: 100%;
    height: 150px;
}

.icon-wrapper,
.project-name {
    display: flex;
    justify-content: center;
    text-align: center;
}

.icon-wrapper .i {
    font-size: 20px;
    color: $sec;
    transform: translateY(0px);
    transition: all 0.6s;
}

.icon-wrapper {
    align-self: center;
}

.project-name {
    margin: auto;
}

.project-name p {
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 2px;
    color: white;
    transform: translateY(0px);
    transition: all 0.5s;
}

.items:hover {
    & .project-name p {
        transform: translateY(-20px);
    }

    & .icon-wrapper .i {
        transform: translateY(-10px);
    }
}
</style>
