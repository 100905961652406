<template>
  <div
    class="wrapper"
    style="padding-bottom: 100px;"
  >
    <b-row style="text-align: left;">
      <div class="contenidoCarrousel transform-line">
        <div class="container">
          <div>
            <div class="carrouselVideos wrap">
              <div
                id="videos"
                class="col-xs-12 col-sm-12 col-md-12 videos frame"
                style="overflow: hidden;"
              >
                <ul class="">
                  <li class="active">
                    <div>
                      <silentbox-single
                        src="https://www.youtube.com/watch?v=HHL-lUtKziE"
                        :autoplay="true"
                        description=""
                      >
                        <img
                          loading="lazy"
                          alt="Arturo Vela"
                          src="../assets/img/videos/video-a1.jpg"
                          class="img-responsive videoImg"
                        >
                      </silentbox-single>
                    </div>
                    <div>
                      <silentbox-single
                        src="https://www.youtube.com/watch?v=O35YDV4g2ok"
                        :autoplay="true"
                        description=""
                      >
                        <img
                          loading="lazy"
                          alt="world"
                          src="../assets/img/videos/video-a2.jpg"
                          class="img-responsive videoImg"
                        >
                      </silentbox-single>
                    </div>
                  </li>

                  <li>
                    <div>
                      <silentbox-single
                        src="https://www.youtube.com/watch?v=q4MeAOkDoXc"
                        :autoplay="true"
                        description=""
                      >
                        <img
                          loading="lazy"
                          alt="Aicad Business School"
                          src="../assets/img/videos/video-a3.jpg"
                          class="img-responsive videoImg"
                        >
                      </silentbox-single>
                    </div>
                  </li>
                  <li>
                    <div>
                      <silentbox-single
                        src="https://www.youtube.com/watch?v=DKRH2JQq55g&feature=youtu.be"
                        :autoplay="true"
                      >
                        <img
                          loading="lazy"
                          alt="video"
                          src="../assets/img/videos/video-a4.jpg"
                          class="img-responsive videoImg"
                        >
                      </silentbox-single>
                    </div>
                    <div>
                      <silentbox-single
                        src="https://www.youtube.com/watch?v=mRNxM1ao1IU"
                        :autoplay="true"
                      >
                        <img
                          loading="lazy"
                          alt="aicad team"
                          src="../assets/img/videos/video-a5.jpg"
                          class="img-responsive videoImg"
                        >
                      </silentbox-single>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-row>
    <b-row>
      <div class="contenidoActualidad">
        <div class="container">
          <div class="col-lg-6 twoCols noticias">
            <p
              style="letter-spacing: -2px;"
              class="titleNoticia"
            >
              <a
                href="#"
                target="_blank"
                style="border-right: none;"
              >
                <img
                  loading="lazy"
                  alt="icon"
                  src="../assets/img/icons/noticia-executive.png"
                  class="img-responsive"
                >
                {{ $t('message.news') }}
              </a>
            </p>
            <div class="bloqueNoticias">
              <div class="col-12 col-md-5 col-xs-12 imgNoticias">
                <img
                  loading="lazy"
                  alt="icon"
                  src="../assets/img/notices/noticia1.jpg"
                  class="img-responsive"
                >
              </div>
              <div class="col-12 col-md-7 col-xs-12 textNoticias">
                <p class="titleN">
                  {{ $t('noticias.Videoconferencia') }}
                </p>
                <p class="descN">
                  {{ $t('noticias.Presidente') }}
                </p>
                <div class="fecha" />
                <div class="btnMas">
                  <a
                    href="https://www.websincoste.com/agente-digital/"
                    target="_blank"
                  >
                    <img
                      v-b-tooltip.hover
                      loading="lazy"
                      alt="icon"
                      :title="$t('message.see_more')"
                      class="iconoMas"
                      src="../assets/img/icons/icono-mas.jpg"
                    >
                  </a>
                </div>
              </div>
            </div>
            <div class="bloqueNoticias">
              <div class="col-12 col-md-5 col-xs-12 imgNoticias">
                <img
                  loading="lazy"
                  alt="noticia"
                  src="../assets/img/notices/noticia1.jpg"
                  class="img-responsive"
                >
              </div>
              <div class="col-12 col-md-7 col-xs-12 textNoticias">
                <p class="titleN">
                  {{ $t('noticias.Selling') }}
                </p>
                <p class="descN">
                  {{ $t('noticias.conocer') }}
                </p>
                <div class="fecha" />
                <div class="btnMas">
                  <a
                    href="https://www.websincoste.com/agente-digital/"
                    target="_blank"
                  >
                    <img
                      v-b-tooltip.hover
                      loading="lazy"
                      alt="icon"
                      :title="$t('message.see_more')"
                      class="iconoMas"
                      src="../assets/img/icons/icono-mas.jpg"
                    >
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 twoCols agenda">
            <p
              style="letter-spacing: -2px;"
              class="titleAgenda"
            >
              <a
                href="agenda/?area=executive"
                target="_blank"
              >
                <img
                  loading="lazy"
                  alt="icon"
                  src="../assets/img/icons/calendario-executive.png"
                  class="img-responsive"
                >
                {{ $t('message.schedule') }}
              </a>
            </p>
            <div class="col-xl-6 col-lg-12 bloqueAgenda">
              <div class="col-xs-12 imgAgenda">
                <p class="titleFecha">
                  MADRID
                </p>
              </div>
              <div class="col-xs-12 textAgenda">
                <p class="descA">
                  {{ $t('noticias.Foro') }}
                </p>
                <div class="btnMas btnA">
                  <a
                    href="https://www.websincoste.com/agente-digital/"
                    target="_blank"
                  >
                    <img
                      v-b-tooltip.hover
                      loading="lazy"
                      alt="icon"
                      :title="$t('message.see_more')"
                      class="iconoMas"
                      src="../assets/img/icons/icono-mas.jpg"
                    >
                  </a>
                </div>
              </div>
            </div>
            <div class="col-12 col-xl-6 col-lg-12 bloqueAgenda">
              <div class="col-xs-12 imgAgenda">
                <p class="titleFecha">
                  BARCELONA
                </p>
              </div>
              <div class="col-xs-12 textAgenda">
                <p class="descA">
                  {{ $t('noticias.Talento') }}
                </p>
                <div class="btnMas btnA">
                  <a
                    href="https://www.websincoste.com/agente-digital/"
                    target="_blank"
                  >
                    <img
                      v-b-tooltip.hover
                      loading="lazy"
                      alt="icon"
                      :title="$t('message.see_more')"
                      class="iconoMas"
                      src="../assets/img/icons/icono-mas.jpg"
                    >
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-row>
  </div>
</template>

<script>
export default {
    name: 'HomeNoticias'
};
</script>

<style scoped lang="scss">
.about .row {
	width: 100%;
	margin-right: 0px;
	margin-left: 0px;
}

.about .row .col {
	padding-left: 0px;
	padding-right: 0px;
}

.img-fluid {
	height: 100%;
}

.carousel-item {
	max-height: 500px;
}

.contenidoFiltros {
	width: 100%;
	height: 100%;
	float: left;
	padding: 31px 0;
	padding-bottom: 50px;
}

.contenidoFiltros p {
	float: left;
	font-weight: 300;
	font-size: 34px;
	margin: 10px;
	padding: 10px 0;
	color: #232323;
	text-transform: uppercase;
	font-family: 'open sans condensed', Arial, sans-serif;
	text-align: left;
	letter-spacing: -4px;
}

.contenidoFiltros p img {
	float: left;
	width: auto;
	margin-right: 10px;
	margin-top: 4px;
}

.contenidoFiltros p span {
	font-weight: 700;
	color: #009090;
	margin-top: -12px;
}

.noPadding {
	padding-left: 0;
	padding-right: 0;
}

.verTodos {
	height: auto;
	float: right;
	padding: 13px;
	border: 1px solid #ffffff;
	background: #009090;
	font-size: 15px;
	color: #ffffff !important;
	margin-bottom: 5px;
	width: 100%;
	text-decoration: none;
	transition: 0.3s ease;
}

a.verTodos:hover {
	opacity: 0.8;
	transition: 0.3s ease;
}

.verTodos img {
	float: right;
	width: auto;
	margin-left: 10px;
}

.contenidoFiltros .col-md-2 {
	padding-left: 0;
	width: 20%;
	padding-right: 8px;
	display: inline-block;
}

.rowSelect2 {
	width: 100%;
	float: left;
	margin: 3px 0;
}

.rowSelect2 select {
	height: auto;
	float: left;
	padding: 14px;
	border: 1px solid #ffffff;
	width: 100%;
	overflow: hidden;
	font-size: 15px;
	color: #ffffff;
	cursor: pointer;
}

.twentypercent {
	width: 20%;
	display: inline-block;
}

.back-gran {
}

.col-xs-12 {
	width: 100%;
	float: left;
}

.openPrograms {
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
	padding-left: 0;
	margin: 0;
	padding-right: 0;
}

.openPrograms .text-title-open-programs {
	font-size: 34px;
	color: #ffffff;
	font-weight: 700;
	text-transform: uppercase;
	font-family: 'open sans condensed', Arial, sans-serif;
	float: left;
}

.openPrograms .col-md-2 {
	display: inline-table;
	padding-right: 2px;
	padding-left: 3px;
	margin-bottom: 40px;
}

.title_desPrograms {
	font-family: 'Open sans', Arial, sans-serif;
	font-weight: 700 !important;
	font-size: 16px !important;
	text-transform: uppercase !important;
	margin: 0px !important;
	padding: 0px !important;
	padding-bottom: 5px !important;
	color: #ffffff;
}

.openPrograms .title_desPrograms ~ .descPrograms {
	color: #ffffff;
	font-family: 'Open sans', Arial, sans-serif;
}

.openPrograms .descPrograms,
.customPrograms .descPrograms {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 14px;
	width: 100%;
	float: left;
	margin: 0px 0;
	padding: 0;
	text-transform: none;
}

.openPrograms .text-title-open-programs span {
	font-weight: 400;
	line-height: 13px;
	margin-bottom: 24px;
}

.back-gran.grey {
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
	background: #4e4e4e;
	top: -3px;
}

.contain-margin-auto {
	display: block;
	margin: 0 auto;
}

.col-md-4.contain-back-grey {
	display: inline-block;
	margin-bottom: 40px;
}

.col-xs-12 {
	position: relative;
}

.contenidoFiltros select,
.contenidoFormulario select {
	-webkit-appearance: none;
	-moz-appearance: none;
	text-indent: 1px;
}

.rowSelect2 {
	margin: unset;
}

.container {
	height: auto;
}

.verTodosProgramas {
	margin-top: auto;
	margin-bottom: auto;
}

.formacionEmpresas {
	text-align: center;
	font-size: 34px;
	color: #ffffff;
	font-family: 'open sans condensed', Arial, sans-serif;
	letter-spacing: -2px;
	margin-bottom: 40px;
}

.formacionEmpresas span {
	font-weight: 400;
	line-height: 13px;
	margin-bottom: 24px;
}

@media screen and (max-width: 767px) {
	.twentypercent {
		width: 100%;
	}

	.textNoticias {
		min-height: unset !important;
	}

	.contenidoFiltros {
		padding: 0px;
	}
}

@media (max-width: 503px) {
	.formacionEmpresas {
		font-size: 19px;
	}
}

@media (min-width: 768px) {
	.openPrograms .col-md-2 {
		-ms-flex: 0 0 20% !important;
		flex: 0 0 20% !important;
		max-width: 20% !important;
	}
}

.transform-line {
	padding: 75px 0 80px;
	z-index: -9999;
}

.contenidoCarrousel {
	width: 100%;
	float: left;
	z-index: 1;
	padding-bottom: 60px;
	padding-top: 0px;
}

.contenidoCarrousel p {
	width: 98%;
	float: left;
	font-weight: 300;
	font-size: 34px;
	margin: 10px;
	padding: 20px 0;
	color: #232323;
	font-family: 'Open sans condensed', Arial, sans-serif;
	text-transform: uppercase;
}

.contenidoCarrousel p span {
	font-weight: 700;
	color: #009090;
}

.contenidoActualidad {
	width: 100%;
	float: left;
	padding: 10px 0 0;
	margin: 20px 0 30px;
}

.twoCols {
	display: inline-block;
	vertical-align: top;
}

.titleNoticia a {
	float: left;
	font-weight: 300;
	font-size: 34px;
	margin: 10px;
	border-right: 1px solid #d5d5d5;
	color: #232323;
	font-family: 'open sans condensed', Arial, sans-serif;
}

.titleAgenda a {
	width: 100%;
	float: left;
	font-weight: 300;
	font-size: 34px;
	margin: 10px;
	color: #232323;
	font-family: 'open sans condensed', Arial, sans-serif;
	text-align: left;
}

.bloqueNoticias {
	width: 100%;
	float: left;
	height: auto;
	margin: 7px 0;
}

.textNoticias {
	background: #f3f3f3;
	padding-top: 10px;
	padding-bottom: 10px;
	min-height: 217px;
}

.imgNoticias,
.imgNoticias img {
	display: inline-block;
	text-align: center;
	height: 182px;
}

.titleN {
	color: #232323;
	font-size: 0.9rem;
	width: 100%;
	float: left;
	margin: 5px 0 14px;
}

.descN {
	width: 100%;
	float: left;
	color: #232323;
	font-size: 0.9rem;
	height: auto;
	margin-bottom: 0;
}

.fecha {
	width: 80%;
	float: left;
	height: auto;
	border-top: 1px solid #ababab;
	margin-top: 0px;
	position: relative;
}

.btnMas {
	width: auto;
	float: right;
	margin-bottom: -10px;
	margin-top: 7px;
	position: absolute;
	bottom: 10px;
	right: 0px;
}

.bloqueAgenda {
	height: auto;
	border: 1px solid #bfbfbf;
	display: inline-block;
	padding-left: 0px;
	padding-right: 0px;
	margin-right: 10px;
}

.imgAgenda {
	display: inline-block;
	text-align: center;
	background: url(../assets/img/notices/background-image-agenda.jpg) no-repeat center;
	height: 167px;
	background-size: cover;
}

.titleFecha {
	width: 72%;
	float: left;
	padding: 62px 0 58px;
	text-align: justify;
	font-size: 25px;
	font-weight: 700;
	color: whitesmoke;
	line-height: 30px;
	margin: 0 45px;
}

.big {
	font-size: 90px;
	font-weight: 700;
}

.smallText {
	font-size: 28px;
	font-weight: 400;
}

.textAgenda {
	background: #f3f3f3;
	padding: 20px;
	width: 100%;
}

.noticias {
	border-right: 1px solid #ababab;
}

img.brands {
	width: 70%;
	height: 0%;
	margin-left: auto;
	margin-right: auto;
}

@media screen and (min-width: 1200px) {
	.bloqueAgenda {
		width: 48%;
	}
}

.carrouselVideos {
	width: 100%;
	float: left;
	height: auto;
	position: relative;
}

.frame ul {
	list-style: none;
	margin: 0;
	padding: 0;
	height: 100%;
	display: block;
	white-space: nowrap;
	font-size: 0;
	overflow: auto;
}

.frame ul li {
	height: 100%;
	padding: 0;
	cursor: pointer;
	position: relative;
	display: inline-block;
	vertical-align: middle;
}

.carouselLogoImg {
	height: 100px;
}

.VueCarousel {
	margin-left: auto;
	margin-right: auto;
	width: 70%;
}

@media screen and (max-width: 767px) {
	.sliderRow {
		display: none;
	}
}

.videoImg:hover {
	opacity: 0.6;
	transition: 0.5s ease;
}

.videoImg {
	transition: 0.5s ease;
}

.iconoMas:hover {
	opacity: 0.8;
	transition: 0.3s ease;
}

.iconoMas {
	transition: 0.3s ease;
}

.carouselLogoImg:hover {
	opacity: 1;
	transform: scale(1.3);
	transition: 0.3s ease;
}

.carouselLogoImg {
	transition: 0.3s ease;
	opacity: 0.8;
}

.carousel-item {
	max-height: unset;
}

@media screen and (max-width: 1199px) {
	.carousel-caption #slideButton {
		margin-left: 83%;
		margin-bottom: 5%;
	}
}

@media screen and (min-width: 1200px) {
	.carousel-caption #slideButton {
		margin-bottom: 9%;
		margin-left: 85%;
	}
}

.descA {
	font-size: 0.9rem;
}

.slider-button {
	width: 15%;
	background: linear-gradient(90deg, rgba(51, 195, 242, 1) 0%, rgba(46, 0, 53, 1) 100%);
	border: 2px solid #fff;
	border-radius: 10px;
	padding: 0.5vw;
	cursor: pointer;
	font-size: 1vw;
	transition: 0.3s ease;

	&:hover {
		transform: scale(1.05);
		box-shadow: 4px 6px 15px 0px rgba(0, 0, 0, 0.75);
	}
}
</style>
