<template>
  <div>
    <div
      id="contactForm"
      class="nineth-contacts footer-contact-form-wrapper styleform"
    >
      <div class="container">
        <div
          v-if="!sentSuccess"
          class="flex contact-title"
        >
          <h1>
            {{ title }} <span>{{ title2 }}</span>
          </h1>
          <div
            v-if="subtitle != ''"
            class="subtitle"
          >
            {{ subtitle }}
          </div>
        </div>
        <form
          v-if="!sentSuccess"
          id="nane"
          data-type="subscription"
          method="post"
          action="#"
          @submit="handleForm"
        >
          <div class="row">
            <div class="col-sm-12 col-lg-4">
              <div class="form-group">
                <label>Nombre</label>
                <input
                  v-model="name"
                  class="form-control"
                  maxlength="200"
                  type="text"
                  name="NOMBRE"
                  placeholder="Nombre"
                  data-required="true"
                  required
                >
              </div>
              <div class="form-group">
                <label>Tipo Empresa</label>
                <select
                  v-model="companySize"
                  class="form-control"
                  name="TIPO_EMPRESA"
                  data-required="true"
                  required
                >
                  <option
                    value=""
                    disabled
                    selected
                  >
                    Seleccione tipo de Empresa
                  </option>
                  <option value="Autonomo o Pequeña Empresa">
                    Autonomo o Pequeña Empresa
                  </option>
                  <option value="Mediana / Gran Empresa">
                    Mediana / Gran Empresa
                  </option>
                  <option value="Asesorías y Consultoras">
                    Asesorías y Consultoras
                  </option>
                  <option value="Otros">
                    Otros
                  </option>
                </select>
              </div>
            </div>
            <div class="col-sm-12 col-lg-4">
              <div class="form-group">
                <label>Teléfono (WhatsApp)</label>
                <input
                  v-model="phone"
                  class="form-control"
                  type="tel"
                  name="SMS"
                  placeholder="Teléfono"
                  data-required="true"
                  required
                >
              </div>
              <div class="form-group">
                <label>Email</label>
                <input
                  v-model="email"
                  class="form-control"
                  type="email"
                  name="EMAIL"
                  placeholder="Email"
                  data-required="true"
                  required
                >
              </div>
              <div class="form-group">
                <label>Ciudad o Localidad</label>
                <input
                  v-model="place"
                  class="form-control"
                  type="text"
                  name="CIUDAD"
                  placeholder="Ciudad o Localidad"
                  data-required="true"
                  required
                >
              </div>
              <div class="form-group" />
            </div>
            <div class="col-sm-12 col-lg-4">
              <div class="form-group">
                <label>Comentarios</label>
                <textarea
                  v-model="comment"
                  rows="8"
                  class="form-control"
                  maxlength="500"
                  name="COMENTARIOS"
                  placeholder="Cuéntanos en qué te podemos ayudar....."
                />
              </div>
            </div>
            <div class="col-sm-12 col-lg-8">
              <div class="form-group">
                <label for="RGPD_OPTIN">
                  <input
                    id="RGPD_OPTIN"
                    v-model="RGPD_OPTIN"
                    type="checkbox"
                    value="He leído y acepto las políticas de privacidad"
                    name="RGPD_OPTIN"
                    required
                  >
                  {{ $t('common_contact_form.read_accept') }}
                  <a
                    target="_blank"
                    href="/aviso-legal-privacidad/"
                  >
                    {{ $t('common_contact_form.privacy_terms') }}
                  </a>
                </label>
              </div>
            </div>
            <div class="col-sm-12 col-lg-4">
              <div class="form-group">
                <button
                  class="btn btn-secondary btn-sm btn-block"
                  type="submit"
                >
                  {{ $t('common_contact_form.send') }}
                </button>
              </div>
            </div>
            <div class="sib-text-form-block col-sm-12 col-lg-12">
              <p>
                {{ $t('convenio.RGPD_youcan') }} <a
                  style="cursor:pointer; color: rgb(4, 101, 245);"
                  @click="showModal()"
                >{{ $t('convenio.RGPD_policy') }}</a>.&nbsp;
              </p>
              <p><strong>{{ $t('convenio.RGPD_responsible_catp') }}</strong>: {{ $t('convenio.RGPD_responsible_answ') }}</p>
              <p><strong>{{ $t('convenio.RGPD_finalidad') }}</strong>: {{ $t('convenio.RGPD_info') }}</p>
              <p><strong>{{ $t('convenio.RGPD_almacenamiento') }}</strong>: {{ $t('convenio.RGPD_almacenamiento_anws') }}</p>
              <p>
                <strong>{{ $t('convenio.RGPD_right') }}</strong>: {{ $t('convenio.RGPD_right_anws') }} <a
                  style="cursor:pointer; color: rgb(4, 101, 245);"
                  @click="showModal()"
                >{{ $t('convenio.RGPD_limits') }}</a>.
              </p>
            </div>
          </div>
        </form>
        <div
          v-if="sentSuccess === true"
          id="thank-you"
        >
          <h1
            id="h1-thank-you"
            style="margin-top: 100px;"
          >
            {{ $t('common_contact_form.thanks_message') }}
          </h1>
          <h3 id="h3-thank-you">
            {{ $t('common_contact_form.will_contact') }}
          </h3>
          <div class="contact-info text-center mt-4">
            <h4>Puedes contactarme por</h4>
            <div class="contact-nfo">
              <font-awesome-icon
                icon="phone"
                class="phone-icon"
              />
              <a href="tel:+34928354668">+34 928 354 668</a>
            </div>
          </div>
        </div>
      </div>
      <LegalModal />
    </div>
    <div id="arrow-bg" />
    <div class="row bottom-clearfix" />
  </div>
</template>
<script>
import LegalModal from '@/components/Reusable/LegalModal.vue';
export default {
    components: {
        LegalModal
    },
    props: {
        web: {
            default: '',
            type: String
        },
        title: {
            default: '',
            type: String
        },
        title2: {
            default: '',
            type: String
        },
        subtitle: {
            default: '',
            type: String
        }
    },
    data() {
        return {
            name: '',
            company: '',
            companySize: '',
            phone: '',
            email: '',
            place: '',
            comment: '',
            sentSuccess: false,
            RGPD_OPTIN: false
        };
    },
    methods: {
        handleForm: function(event) {
            event.preventDefault();
            this.axios
                .post('https://www.websincoste.com/index.php', {
                    _nonce: 'f9d8d3d927baca5e53d95c32b41f6f00',
                    action: 'send_universal_form',
                    serialize: [{ name: 'Form Type', value: 'Footer Contact Form' }, { name: 'Nombre', value: this.name }, { name: 'Particular Empresa', value: this.company }, { name: 'Tipo Empresa', value: this.companySize }, { name: 'Teléfono', value: this.phone }, { name: 'Email', value: this.email }, { name: 'Ciudad o Localidad', value: this.place }, { name: 'Comentarios', value: this.comment }, { name: 'Reference Link', value: window.location.href }, { name: 'Aceptación Privacidad RGDP:', value: this.RGPD_OPTIN }]
                })
                .then(response => {
                    if (response.status === 200) {
                        this.sentSuccess = true;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        showModal: function() {
            this.$modal.show('modal-footer');
        },
        hide() {
            this.$modal.hide('modal-footer');
        }
    }
};
</script>
<style lang="scss" scoped>
#contactForm {
	text-align: left;
}

.styleform{
  padding-top: 80px;
  padding-bottom: 50px;
  @media screen and (max-width: 770px) {
    padding-top: 20px;
  }
}

.nineth-contacts h1 {
	font-weight: 400;
	margin: 0;
	color: #fff;
}

@media only screen and (max-width: 1400px) and (min-width: 1200px){
  #app .nineth-contacts {
      padding-top: 7%;
  }
}

@media only screen and (max-width: 1800px) and (min-width: 1500px){
  #app .nineth-contacts {
      padding-top: 6%;
  }
}

@media only screen and (max-width: 2700px) and (min-width: 1800px){
  #app .nineth-contacts {
      padding-top: 4%;
  }
}

@media only screen and (min-width: 3200px){
  #app .nineth-contacts {
      padding-top: 7%;
  }
}

#h3-thank-you {
	font-weight: 300;
}

.nineth-contacts .form-control {
	border-radius: 0;
	background-color: #fafafa;
	font-size: 14px;
}

.nineth-contacts .form-group label {
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 0;
}

.nineth-contacts .btn {
	border-radius: 0;
}

.nineth-contacts form {
	padding-top: 15px;
	border-top: 1px solid #888;
}
#sib-form div {
	color: #fff;
}
#sib-form div,
#sib-form h1,
#sib-form p,
#sib-form label,
#sib-form span {
	color: #fff !important;
}
.sib-form {
	background-color: transparent !important;
	padding: 16px 12px !important;
}
#sib-container {
	background-color: rgba(0, 0, 0, 0) !important;
	border: none !important;
	padding: 10px !important;
}
#sib-form option,
#sib-form select,
#sib-form input {
	color: #000;
}
#sib-form input::placeholder {
	color: #000;
	padding-left: 10px;
}
.sib-text-form-block p {
	font-size: 13px !important;
}
.sib-optin.sib-form-block {
	padding: 0 40px;
}
p {
  margin-top: 0;
  margin-bottom: 0rem;
  font-size: 12px;
}
</style>
