import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import axios from 'axios';
import VueAxios from 'vue-axios';

import VModal from 'vue-js-modal';
Vue.use(VModal);
Vue.use(VueAxios, axios);

import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

import VueMeta from 'vue-meta';
Vue.use(VueMeta, {});

import VueSilentbox from 'vue-silentbox';
Vue.use(VueSilentbox);

import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
import messages from './lang/messages.json';
const i18n = new VueI18n({
    locale: 'es',
    fallbackLocale: 'en',
    messages
});

router.beforeEach((from, to, next) => {
    let language = from.params.lang;
    if (!language) {
        language = 'es';
    }
    i18n.locale = language;
    next();
});

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import { faFacebook, faTwitter, faLinkedin, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

library.add(fas, faFacebook, faTwitter, faLinkedin, faInstagram, faYoutube);
library.add(fab);
library.add(far);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);

import NProgress from 'vue-nprogress';
Vue.use(NProgress);
const nprogress = new NProgress({ parent: '.nprogress-container' });

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

import VueScrollto from 'vue-scrollto';
Vue.use(VueScrollto);

import vueSmoothScroll from 'vue2-smooth-scroll';
Vue.use(vueSmoothScroll);

import responsive from 'vue-responsive';
Vue.use(responsive);

import AOS from 'aos';
import 'aos/dist/aos.css';

import '@/assets/global.css';
Vue.config.productionTip = false;

new Vue({
    nprogress,
    i18n,
    router,
    store,
    created() {
        AOS.init();
        window.addEventListener('load', AOS.refresh);
    },
    render: h => h(App)
}).$mount('#app');
