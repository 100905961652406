<template>
  <div class="wrapper mb-5 py-5 bg">
    <div class="container text-white">
      <carousel
        :per-page="5"
        :mouse-drag="true"
        :loop="true"
        :scroll-per-page="false"
        :pagination-enabled="false"
        :navigation-enabled="true"
        :autoplay="true"
        :autoplay-timeout="5000"
        :min-swipe-distance="4"
        :per-page-custom="[[320, 1], [576, 2], [768, 4], [1199, 5]]"
      >
        <slide
          v-for="member in team"
          :key="member.name"
        >
          <img
            loading="lazy"
            alt="Team member"
            class="photo"
            :src="getImgUrl(member.image)"
          >
          <div class="memb">
            {{ member.name }}
          </div>
          <a
            href="https://www.websincoste.com/delegaciones/"
            target="_blank"
          ><p>{{ member.position }}</p></a>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
    components: {
        Carousel,
        Slide
    },
    data() {
        return {
            team: [
                {
                    name: 'Hermel Balcázar',
                    position: 'Executive Director/CEO',
                    image: '1.png'
                },
                {
                    name: 'Eva Afonso',
                    position: 'Corporate Project Manager',
                    image: '31.png'
                },
                {
                    name: 'M. Yasin Birer',
                    position: 'Chief Technology Officer',
                    image: '29.png'
                },
                {
                    name: 'Costel Bozomala',
                    position: 'Marketing and Communication',
                    image: '11.png'
                },
                {
                    name: 'Lilit Sargsyan',
                    position: 'Europe Human Resources Technician',
                    image: '21.png'
                },
                {
                    name: 'Jesús Mata',
                    position: 'Software Engineer - SRE',
                    image: '30.png'
                },
                {
                    name: 'José Antonio Callero',
                    position: 'Product Developer',
                    image: '24.png'
                },
                {
                    name: 'Mikel Anakabe Ondarre',
                    position: 'Associate Vigo (Spain)',
                    image: '27.png'
                },
                {
                    name: 'José Manuel Cosío',
                    position: 'Associate Madrid Juan Bravo (Spain)',
                    image: '15.png'
                },
                {
                    name: 'Miguel Angel Osuna',
                    position: 'Associate Barcelona (Spain)',
                    image: '26.png'
                },
                {
                    name: 'Roberto Ruiz Yécora',
                    position: 'Associate La Rioja (Spain)',
                    image: '19.png'
                },
                {
                    name: 'Carolina Salvador Moliner',
                    position: 'Associate Castellón (Spain)',
                    image: '12.png'
                },
                {
                    name: 'Elena Zalbide Elorduy',
                    position: 'Associate Bilbao (Spain)',
                    image: '10.png'
                },
            ]
        };
    },
    methods: {
        getImgUrl(pic) {
            return require('../../assets/team/' + pic);
        }
    }
};
</script>

<style scoped lang="scss">
.container {
    z-index: 3;
    text-align: center;
}

img.photo {
    width: 160px;
    transition: 0.3s ease;
    -webkit-filter: grayscale(80%);
    filter: grayscale(80%);
    &:hover {
        transform: scale(1.03);
    }
}
.bg {
}
hr {
    background: black;
    width: 10%;
}

.memb {
    color: black;
}

.team-row {
    margin-bottom: 1rem;
}

p {
    font-size: 13px;
    color: black;
}
</style>

<style>
.VueCarousel-navigation-button {
    color: black !important;
    top: 40% !important;
}

@media screen and (max-width: 767px) {
    .VueCarousel-navigation-button {
        position: relative !important;
    }

    .VueCarousel-navigation {
        margin-top: 25px;
    }
}
</style>
