<template>
  <div class="wrapper">
    <div class="container">
      <b-row>
        <b-col
          lg="6"
          md="12"
          sm="12"
        >
          <div
            class="box"
            data-aos="fade-in"
            data-aos-duration="200"
            data-aos-delay="100"
          >
            <font-awesome-icon
              style="color: white;"
              size="lg"
              icon="comment-alt"
            />
            <h2 class="text-white">
              {{ $t('franquicias.Compartimos') }}
            </h2>
            <p class="text">
              {{ $t('franquicias.Nos_gustaría') }}
            </p>
          </div>
        </b-col>
        <b-col
          lg="6"
          md="12"
          sm="12"
          data-aos="zoom-in-up"
          data-aos-duration="300"
          data-aos-delay="100"
        >
          <div class="last">
            <h2 class="h2">
              {{ $t('franquicias.FRANQUICIAS') }}
            </h2>
            <h3 class="h2">
              {{ $t('franquicias.Franquíciate') }}
            </h3>
            <p class="text">
              {{ $t('franquicias.buscamos') }}
            </p>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Franquicias'
};
</script>

<style scoped lang="scss">
.wrapper {
	background: #009090;
	padding: 100px 0 50px 0;
	.box {
		margin: 0 auto;
		position: relative;
		-webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
		-moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
		box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
		padding: 30px;
		width: 20vw;
		min-width: 220px;
		max-width: 500px;
		text-align: center;
		background: rgb(53, 176, 208);
		background: linear-gradient(90deg, rgba(53, 176, 208, 1) 17%, rgba(154, 219, 167, 1) 100%);

		h2 {
			font-size: 1.5em;
			text-align: center;
			align-content: center;
			align-self: center;
			align-items: center;
			font-family: 'LatoWebBlack', sans-serif;
			letter-spacing: 3px;

      @media only screen and (max-width: 1049px) and (min-width: 763px){
        font-size: 1.2em;
      }
		}
		@media only screen and (max-width: 424px) {
			width: 100%;
		}
	}
  @media screen and (max-width: 770px) {
    padding: 50px 0 50px 0;
    margin-top: 50px;
  }
}
.img {
	position: relative;
	width: 60vw;
	max-width: 50px;
	float: right;
}

.h3 {
	margin-top: -20px;
	font-family: 'fabfeltscriptbold', sans-serif;
	font-size: 3rem;
	letter-spacing: 5px;
	color: white;
}

.h2 {
	font-family: 'LatoWebBlack', sans-serif;
	font-size: 1.5rem;
	letter-spacing: 4px;
	color: white;
}

p {
	font-family: 'LatoWebMedium', sans-serif;
	color: white;
}
.last {
	margin-top: 50px;
}
</style>
