<template>
  <div id="app">
    <nprogress-container />
    <Header />
    <div>
      <router-view :key="$route.path" />
    </div>
    <div class="footer-contact">
      <Contact
        web="Digital Marketing"
        :title="$t('message_form.apply_now')"
        :subtitle="$t('message_form.leave_questions')"
        :title2="$t('message_form.apply_now_2')"
      />
      <Seperator />
      <Footer />
      <AcceptCookies />
    </div>
    <a
      v-smooth-scroll="{ duration: 1000 }"
      class="angleUp2"
      href="#app"
      :class="{ 'angleUp2--hidden': showAngleUp2 }"
    >
      <font-awesome-icon
        class="doubleUpIcon"
        :icon="['fas', 'angle-double-up']"
      />
    </a>
  </div>
</template>

<script>
import NprogressContainer from 'vue-nprogress/src/NprogressContainer';

import Header from '@/components/Header.vue';
import Contact from '@/components/Contact';
import Footer from '@/components/Footer.vue';
import Seperator from '@/components/Reusable/Seperator.vue';
import AcceptCookies from '@/components/AcceptCookies.vue'

export default {
    components: {
        NprogressContainer,
        Header,
        Footer,
        Contact,
        Seperator,
        AcceptCookies
    },
    data() {
        return {
            showAngleUp2: true,
            lastScrollPosition: 0
        };
    },
    metaInfo() {
        return {
            title: this.$t('meta.aicad.title'),
            htmlAttrs: {
                lang: 'es'
            },
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.$t('meta.aicad.description')
                }
            ]
        };
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll);
    },
    methods: {
        onScroll() {
            const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
            if (currentScrollPosition < 0) {
                return;
            }
            if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
                return;
            }
            this.showAngleUp2 = currentScrollPosition < this.lastScrollPosition;
            this.lastScrollPosition = currentScrollPosition;
        }
    }
};
</script>

<style lang="scss">
@import 'assets/fonts/fonts.css';
@import 'assets/font.css';

#app {
	overflow: hidden !important;

	.angleUp2 {
		z-index: 99999999;
		position: fixed;
		@media only screen and (max-width: 324px) {
			left: 45% !important;
			background-color: rgba(0, 0, 0, 0.2);
			border-radius: 10px;
		}
		@media only screen and (max-width: 426px) {
			left: 86%;
		}
		@media only screen and (max-width: 800px) {
			left: 84%;
		}
		top: 83%;
		left: 95%;
		padding: 10px 20px 10px 20px;
		color: white;
		background-color: rgba(0, 0, 0, 0.4);
		transform: translate3d(0, 0, 0);
		transition: 0.1s all ease-out;
	}
	.angleUp2 .socialIcon {
		border-color: white;
		background: black;
	}

	svg.doubleUpIcon2:hover {
		opacity: 0.8;
		transition: 0.3s ease;
	}

	svg.doubleUpIcon2 {
		transition: 0.3s ease;
	}

	.angleUp2.angleUp2--hidden {
		box-shadow: none;
		transform: translate3d(340%, 0, 0);
	}
	.h1-black {
		font-family: LatoWebBlack, sans-serif;
	}
	.h1-fab {
		font-family: fabfeltscriptbold, sans-serif;
	}
	.h1-light {
		font-family: LatoWebLight, sans-serif;
	}
	.h1-medium {
		font-family: LatoWebMedium, sans-serif;
	}
	.h1-medium-italic {
		font-family: LatoWebMediumItalic, sans-serif;
	}
	#nprogress .bar {
		background: whitesmoke !important;
	}

	.nprogress-container {
		height: 10px;
		position: fixed;
		width: 100%;
		top: 0;
		left: 0;
		z-index: 99999;
	}
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;

	.input-hidden {
		display: none;
	}

	.nineth-contacts {
		background-color: #e6e6e6;
		width: 100%;
		padding: 40px 0 20px;
	}

	.bordered-img {
		border-radius: 10px;
		width: 100%;
		position: relative;
	}
	.grey {
		color: #bbbbbb;
	}
	.whitesmoke {
		color: whitesmoke;
	}
	.flex {
		display: flex;
	}

	.flex-wrap {
		display: flex;
		flex-wrap: wrap;
	}

	.rowInput2 {
		margin: 3px 0;
		width: 98%;
		float: left;
	}

	.rowInput2 input {
		width: 98% !important;
		float: left;
		background: #ffffffc2;
		border: 1px solid #686868;
		padding: 10px;
		color: black;
		font-size: 14px;
	}

	textarea#COMENTARIOS {
		width: 98%;
		float: left;
		background: #ffffffc2;
		border: 1px solid #686868;
		padding: 9px 10px;
		color: black;
		font-size: 14px;
		margin-top: 3px;
		min-height: 137px;
	}

	.contactCol {
		padding: 0;
	}

	.contacts-bottom {
		flex-wrap: wrap;
		display: flex;
	}

	#contacts-arrow {
		position: absolute;
		left: 50%;
		transform: translate(-50%);
		margin-top: -1px;
	}

	#contacts-arrow img {
		z-index: 3;
	}

	#arrow-bg {
		background: #117e7b;
	}

	.nineth-contacts {
		color: #232323;
	}

	.nineth-contacts h1 {
		color: #005858;
		font-size: 2rem;
	}

	.nineth-contacts .subtitle {
		color: #004a4a;
	}

	.nineth-contacts h1 span {
		font-weight: 700;
	}

	.send {
		width: 45%;
		color: #fff;
		font-size: 16px;
		float: right;
		background: transparent;
		cursor: pointer;
	}

	.send #a {
		border: 3px solid #fff;
		float: left;
		width: 100%;
		padding: 7px 10px;
		color: white;
	}

	.send img {
		float: right;
		margin-top: 5px;
	}

	.contact-title {
		margin: 0 0 15px;
		flex-wrap: wrap;

		h1 {
			width: 100%;
		}
	}

	.contacts-bottom label {
		font-size: 0.9rem;
	}

	.contacts-bottom {
		margin-top: 10px;
	}

	.contacts-bottom label a {
		color: white;
		text-decoration: underline;
	}

	.contacts-bottom label a:hover {
		text-decoration: none;
		color: #adb5bd;
	}

	.bottom-clearfix {
		margin: 0 !important;
	}

	.select-company-size {
		width: 98%;
		float: left;
		background: #ffffffc2;
		border: 1px solid #686868;
		padding: 10px;
		color: #757575;
		font-size: 14px;
	}

	.phone-code {
		margin-left: -15px;
		margin-top: 5px;
		width: 160%;
		height: 40px;
		background: #ffffffc2;
		border: 1px solid #686868;
		color: #757575;
		font-size: 14px;
	}

	.telef {
		margin-left: 20px;
		margin-top: 5px;
		height: 40px;
		width: 100% !important;
	}

	.select-business-img {
		width: 100px;
	}

	.solutions span {
		font-family: adellesage;
		margin-right: 20px;
	}

	.solutions span a {
		color: white;
	}

	#send-btn {
		float: right;
		background: transparent;
		border: 2px solid white;
		color: white;
		padding: 5px 20px;
		background: url(./assets/img/icons/icon-send.png);
		background-repeat: no-repeat;
		background-position: 90% center;
		padding-right: 40px;
		text-transform: uppercase;
		transition: 0.3s ease;
	}

	#send-btn:hover {
		transform: scale(1.1);
	}

	#h1-thank-you {
		text-align: center;
		font-size: 3rem;
	}

	#h3-thank-you {
		text-align: center;
	}

	#rgpd {
		margin: 20px;
	}

	#rgpd a {
		color: white;
		text-decoration: underline;
	}

	#rgpd a:hover {
		text-decoration: none;
		color: #adb5bd;
	}
}

@media screen and (min-width: 1444px) {
	#router-view {
		background-image: url('./assets/shape.svg');
		background-attachment: fixed;
		background-repeat: no-repeat;
		overflow: hidden;
		background-size: cover;
	}
	.footer-contact {
		background-attachment: fixed;
		background-repeat: no-repeat;
		overflow: hidden;
	}
}

@media screen and (max-width: 1444px) {
	#router-view {
		background-image: url('./assets/shape.svg');
		background-attachment: fixed;
		background-repeat: no-repeat;
		background-size: cover;
		overflow: hidden;
	}
	.footer-contact {
		background-attachment: fixed;
		background-repeat: no-repeat;
		overflow: hidden;
	}
}

@media screen and (max-width: 430px) {
	#app {
		background-attachment: fixed;
		background-image: url('./assets/shape.svg');
		background-repeat: no-repeat;
		background-size: cover;
		overflow: hidden;
	}
	.footer-contact {
		background-attachment: initial;
		background-image: none;
		overflow: hidden;
	}
}
</style>
