<template>
  <div class="footer">
    <div class="mainFooter">
      <div class="col-8 col-lg-4 footThreeCols oficinas">
        <div>
          <h2>
            <img 
              loading="lazy"
              alt="icon"
              src="../assets/img/icons/office.png"
            >
            {{ $t('message.offices') }}
          </h2>
          <div
            class="col-6 col-lg-6"
            style="display:inline-block;"
          >
            <ul class="officinas">
              <li>
                <a
                  href="/granada/"
                >{{ $t('footer.Granada') }}</a>
              </li>
              <li>
                <a
                  href="/malaga/"
                >{{ $t('footer.Málaga') }}</a>
              </li>
              <li>
                <a
                  href="/zaragoza/"
                >{{ $t('footer.Zaragoza') }}</a>
              </li>
              <li>
                <a
                  href="/lugones/"
                >{{ $t('footer.Lugones_Asturias') }}</a>
              </li>
              <li>
                <a
                  href="/cuenca/"
                >{{ $t('footer.Cuenca') }}</a>
              </li>
              <li>
                <a
                  href="/valladolid/"
                >{{ $t('footer.Valladolid') }}</a>
              </li>
              <li>
                <a
                  href="/barcelona-centro/"
                >{{ $t('footer.Barcelona') }}</a>
              </li>
              <li>
                <a
                  href="/alicante/"
                >{{ $t('footer.Alicante') }}</a>
              </li>
              <li>
                <a
                  href="/castellon/"
                >{{ $t('footer.Castellón') }}</a>
              </li>
              <li>
                <a
                  href="/coruna/"
                >{{ $t('footer.Coruña') }}</a>
              </li>
              <li>
                <a
                  href="/vigo/"
                >{{ $t('footer.Vigo') }}</a>
              </li>
              <li>
                <a
                  href="/ibiza/"
                >{{ $t('footer.Islas_Baleares') }}</a>
              </li>
            </ul>
          </div>
          <div
            class="col-6 col-lg-6"
            style="display:inline-block;"
          >
            <ul class="officinas">
              <li>
                <a
                  href="/tenerife/"
                >{{ $t('footer.Tenerife') }}</a>
              </li>
              <li>
                <a
                  href="/leganes/"
                >{{ $t('footer.Madrid_Leganes') }}</a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="http://www.aicad-madrid.es"
                >{{ $t('footer.Juan_Bravo') }}</a>
              </li>
              <li>
                <a href="/larioja/">{{ $t('footer.La_Rioja') }}</a>
              </li>
              <li>
                <a href="/rioja-navarra/">{{ $t('footer.Navarra') }}</a>
              </li>
              <li>
                <a
                  href="/bilbao/"
                >{{ $t('footer.Bilbao') }}</a>
              </li>
              <p />
              <li>
                <a
                  href="/dusseldorf-germany/"
                >{{ $t('footer.Germany') }}</a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="/cursos-y-masteres/"
                >{{ $t('footer.Guatemala') }}</a>
              </li>
              <li>
                <a
                  rel="nofollow"
                  target="_blank"
                  href="http://aicad.com.mx/"
                >{{ $t('footer.Mexico') }}</a>
              </li>
              <li>
                <a
                  rel="nofollow"
                  target="_blank"
                  href="http://aicad.cl/"
                >{{ $t('footer.Chile') }}</a>
              </li>
              <li>
                <a
                  rel="nofollow"
                  target="_blank"
                  href="http://aicad.do/"
                >{{ $t('footer.Dominicana') }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-4 footThreeCols noSmallCall">
        <div style="height: 100px" />
      </div>
      <div
        class="col-10 col-sm-6 col-md-6 col-lg-4 footThreeCols contacts"
        style="text-align: left; vertical-align: top;"
      >
        <div
          id="contactCol"
          class="footerCols"
          style="margin-right: 0px;"
        >
          <div>
            <a
              target="_blank"
              rel="nofollow"
              href="https://twitter.com/abusinessschool/"
            >
              <font-awesome-icon
                :icon="['fab', 'twitter']"
                class="socialIcon"
              />
            </a>
            <a
              target="_blank"
              rel="nofollow"
              href="https://www.facebook.com/AICADBUSINESSSCHOOL/"
            >
              <font-awesome-icon
                :icon="['fab', 'facebook']"
                class="socialIcon"
              />
            </a>
            <a
              target="_blank"
              rel="nofollow"
              href="https://es.linkedin.com/school/aicad-business-school/"
            >
              <font-awesome-icon
                :icon="['fab', 'linkedin']"
                class="socialIcon"
              />
            </a>
            <a
              target="_blank"
              rel="nofollow"
              href="https://www.youtube.com/channel/UC1AZ0pvMZUCl6ete8j6KTZw"
            >
              <font-awesome-icon
                :icon="['fab', 'youtube']"
                class="socialIcon"
              />
            </a>
            <a
              target="_blank"
              rel="nofollow"
              href="https://www.instagram.com/aicad/"
            >
              <font-awesome-icon
                :icon="['fab', 'instagram']"
                class="socialIcon"
              />
            </a>
          </div>
          <div class="phoneDiv">
            <a
              href="tel:8496242332"
              class="footerNumber"
            >{{ $t('footer.tel_f') }}</a>
            <span>{{ $t('footer.REPÚBLICA_DOMINICANA') }}</span>
          </div>
          <div class="phoneDiv">
            <a
              href="tel:8496242332"
              class="footerNumber"
            >{{ $t('footer.tel2') }}</a>
            <span>{{ $t('footer.GUATEMALA') }}</span>
          </div>
          <div class="phoneDiv">
            <a
              href="tel:8496242332"
              class="footerNumber"
            >{{ $t('footer.tel3') }}</a>
            <span>{{ $t('footer.ECUADOR') }}</span>
          </div>
          <div class="phoneDiv">
            <a
              href="tel:8496242332"
              class="footerNumber"
            >{{ $t('footer.tel4') }}</a>
            <span>{{ $t('footer.COSTA_RICA') }}</span>
          </div>
          <div class="phoneDiv">
            <a
              href="tel:8496242332"
              class="footerNumber"
            >{{ $t('footer.tel5') }}</a>
            <span>{{ $t('footer.ITALIA') }}</span>
          </div>
          <div class="phoneDiv">
            <a
              href="tel:8496242332"
              class="footerNumber"
            >{{ $t('footer.tel6') }}</a>
            <span>{{ $t('footer.ALEMANIA') }}</span>
          </div>
          <div class="btnEnviar formPie">
            <router-link
              v-smooth-scroll="{ duration: 500, offset: -100 }"
              to="/contacta-con-nosotros"
            >
              {{ $t('message.contact') }}
              <font-awesome-icon
                :icon="['fas', 'phone']"
                style="float: right;"
              />
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <svg
      loading="lazy"
      alt="image"
      class="kampas"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
      style="background-color: #333;margin-bottom:20px; height: 100px;width: 100%;transform: scale(1, -1);"
    >
      <polygon
        fill="#f9f9f9"
        points="0,100 100,0 0,0"
      />
    </svg>
    <a
      v-smooth-scroll="{ duration: 350 }"
      class="angleUp"
      href="#app"
    >
      <font-awesome-icon
        class="doubleUpIcon"
        :icon="['fas', 'angle-double-up']"
      />
    </a>
    <div
      class="subFooter"
      style="background-color: #f9f9f9; margin-top: -20px;"
    >
      <div
        class="beforeFooterEnd hidden-sm-down"
        style="margin-bottom: 30px;"
      >
        <div style="width:60%; margin:auto;">
          <div class="twoCols">
            <div class="title-effect">
              <span
                class="first"
                style="display: block;text-transform: uppercase;color: #333; font-size:36px;line-height: 25px;"
              >{{ $t('footer.Get') }}</span>
              <div
                class="rw-words rw-words-1"
                style="letter-spacing: normal;color: #333;text-transform: uppercase;font-weight: 700;line-height: 44px;font-size:48px;display: inline;"
              >
                <span style="position: absolute; animation-delay: 0s">{{ $t('footer.more') }}</span>
                <span style="position: absolute; animation-delay: 2s">{{ $t('footer.the_best') }}</span>
                <span style="position: absolute; animation-delay: 4s">{{ $t('footer.the_best') }}</span>
              </div>
              <hr style="margin-top: 25px;visibility: hidden;margin-bottom: 0px;">
              <span
                class="title-effect-sub"
                style="line-height: 2.4em;font-size: 22px;text-transform: lowercase;color: #666;font-weight: 400;white-space: nowrap;"
              >{{ $t('footer.of_human_talent') }}</span>
            </div>
          </div>
          <div
            class="twoCols"
            style="text-align: right;"
          >
            <a
              href="/resources/iso_spanish.pdf"
              target="_blank"
            >
              <img
                loading="lazy"
                alt="logo"
                src="../assets/img/logo-ISO9001_bw.png"
              >
            </a>
            <img
              loading="lazy"
              alt="logo"
              src="../assets/img/footLogos.png"
            >
            <img
              loading="lazy"
              alt="logo"
              src="../assets/img/aeen - logos secundario 1.jpg"
              style="width:90px"
            >
          </div>
        </div>
      </div>
      <div class="footerEnd">
        <div style="width: 60%;margin: auto;display:flex;flex-wrap:wrap;">
          <a
            target="_blank"
            href="/page/aviso-legal-privacidad/"
          >{{ $t('message.privacy_policy') }}</a>
          <a
            href="/delegaciones/"
          >{{ $t('message.delegations') }}</a>
          <a
            target="_blank"
            href="/page/condiciones-de-contratacion/"
          >{{ $t('message.general_conditions') }}</a>
          <a
            target="_blank"
            href="/page/politica-de-cookies/"
            app
          >{{ $t('message.cookies_policy') }}</a>
          <a
            target="_blank"
            href="/preguntas-frecuentes/"
          >{{ $t('message.FAQ') }}</a>
          <a
            href="/resources/iso_spanish.pdf"
            target="_blank"
          >{{ $t('Certificación de Calidad') }}</a>
          <a
            href="/resources/portal-transparencia.pdf"
            target="_blank"
          >{{ $t('Portal de transparencia') }}</a>
          <a style="width:50px" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    methods: {
        login() {
            this.$router.push({ name: 'Login' });
        },
        logout() {
            this.$auth.logout();
            this.$router.push({ name: 'Login' });
        },
        dashboard() {
            this.$router.push({ name: 'dashboard' });
        }
    }
};
</script>

<style scoped>
.footer {
	margin-top: 120px;
}

.footerEnd {
	line-height: 60px;
	background: #f2f2f2;
	text-align: center;
}
.footerEnd a {
	color: #666;
	font-size: 13px;
	padding: 0 15px 0 0;
}

.subFooter .twoCols {
	display: inline-block;
	width: 49%;
}

.beforeFooterEnd .title-effect .rw-words-1 span {
	animation-name: rotateWordsFirst;
	animation-duration: 6s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	animation-delay: 0s;
	opacity: 0;
	margin-left: -4px;
}

@keyframes rotateWordsFirst {
	0% {
		opacity: 0;
		animation-timing-function: linear;
		height: 0;
	}
	8% {
		opacity: 1;
	}
	19% {
		opacity: 1;
	}
	40% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

.title-effect {
  text-align: left;
  margin-top: 20px;
}

@media screen and (max-width: 790px) {
	.twoCols img {
		visibility: hidden;
	}
}

.mainFooter {
	margin-top: -120px;
	background: #333 url(../assets/images/footerImage.png) no-repeat;
	background-size: contain;
	background-position: center;
}

.socialIcon {
	width: 45px;
	height: 45px;
	border-radius: 50%;
	padding: 12px;
	border: 2px solid #fff;
	color: #fff;
}

.footerCols a {
	margin-right: 5px;
	line-height: 7px;
}

.footerNumber {
	display: block;
	font-size: 1.1rem;
	font-weight: 700;
	color: #fff;
	letter-spacing: 1px;
	margin-top: 15px;
}

.col-8{
  @media only screen and (max-width: 1049px) and (min-width: 763px){
    max-width: 50% !important;
  }
}

.phoneDiv {
	margin-bottom: 15px;
}

.footerCols span {
	color: #fff;
	font-size: 12px;
	letter-spacing: 1px;
}

.footThreeCols {
	display: inline-block;
	margin-top: 30px;
	text-align: right;
  @media only screen and (max-width: 1049px) and (min-width: 763px){
    width: 50% !important;
  }
}

ul.officinas {
	list-style: none;
}

.officinas a {
	color: #fff;
	font-size: 0.91rem;
}

.angleUp {
	position: relative;
	float: right;
	margin-right: 18%;
	margin-top: -60px;
  display: none !important;
}

.angleUp .socialIcon {
	color: white;
	border-color: white;
	background: black;
}

.btnEnviar a {
	border: 3px solid #fff;
	float: left;
	width: 100%;
	padding: 11px 10px;
	text-align: left;
	color: #fff;
	text-decoration: none;
}

.btnEnviar {
	width: 45%;
	color: black;
	font-size: 16px;
	background: transparent;
	margin-bottom: 6px;
	float: left;
}

.formPie {
	width: 30%;
	margin-top: 10px;
	margin-right: 12px;
}

@media screen and (max-width: 991px) {
	.noSmallCall {
		display: none;
	}

	.mainFooter {
		background: #333;
	}
}

@media screen and (max-width: 575px) {
	.oficinas {
		display: none;
	}

	.contacts {
		text-align: center !important;
    max-width: 100%;
	}

	.formPie {
		width: 50%;
		margin-right: auto;
		margin-left: auto;
	}

	.formPie a {
		text-align: center;
	}

  .footer {
    margin-top: 120px;
  }

  .footerEnd {
    line-height: 20px;
    background: #f2f2f2;
    text-align: center;
    display: none !important;
  }
  .footerEnd a {
    color: #666;
    font-size: 13px;
    padding: 0 15px 0 0;
  }

  .formPie{
      width: 100%;
  }

  .angleUp{
      position: relative;
      float: right;
      margin-right: 18%;
      margin-top: -60px;
      display: none !important;
  }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px){
  .officinas a {
    font-size: 0.51rem;
    letter-spacing: 0.7px;
  }

  .footerNumber{
      font-size: 0.8rem;
  }

  .footerCols span {
      font-size: 10px;
  }

}

@media only screen and (max-width: 1049px) and (min-width: 763px){
  .officinas a {
    font-size: 0.5rem;
  }
  .oficinas h2 {
    font-size: 25px;
  }

  .footThreeCols{
    width: 50% !important;
  }
  .footerNumber{
    font-size: 0.8rem;
  }

  .angleUp{
      display: none !important;
  }

  .footerEnd{
      display: none !important;
  }
}


.oficinas h2 {
	font-weight: 300;
	font-size: 34px;
	margin: 10px;
	color: #fff;
	font-family: 'open sans condensed', Arial, sans-serif;
	text-transform: uppercase;
	letter-spacing: -2px;
  @media only screen and (max-width: 1049px) and (min-width: 763px){
    font-size: 25px;
  }
}

svg.socialIcon:hover {
	border-color: #333;
	transition: 0.3s ease;
}
svg.socialIcon {
	transition: 0.3s ease;
}

svg.socialIcon.svg-inline--fa.fa-youtube.fa-w-18:hover {
	color: red;
}

svg.socialIcon.svg-inline--fa.fa-facebook.fa-w-14:hover {
	color: #3c5a99;
}

svg.socialIcon.svg-inline--fa.fa-twitter.fa-w-16:hover {
	color: #1bb2e9;
}

svg.socialIcon.svg-inline--fa.fa-linkedin.fa-w-14:hover {
	color: #006699;
}

a.footerNumber {
	text-decoration: none;
	transition: 0.3s ease;
  @media only screen and (max-width: 1049px) and (min-width: 763px){
    font-size: 0.8rem;
  }
}

a.footerNumber:hover {
	opacity: 0.6;
	transition: 0.3s ease;
}

.btnEnviar a:hover {
	transform: scale(1.05);
	transition: 0.3s ease;
	color: black !important;
}

.btnEnviar a {
	transition: 0.3s ease;
}

.doubleUpIcon {
	border-radius: 50%;
	padding: 12px;
	width: 40px;
	height: 40px;
	color: white;
	background: black;
}

svg.doubleUpIcon:hover {
	opacity: 0.8;
	transition: 0.3s ease;
}

svg.doubleUpIcon {
	transition: 0.3s ease;
}

.officinas a:hover {
	opacity: 0.8;
	transition: 0.3s ease;
}

.officinas a {
	transition: 0.3s ease;
}


.hidden-sm-down {
  display: none !important;
}

@media screen and (max-width: 1219px) {
	.formPie {
		width: 40%;
	}
}
</style>
