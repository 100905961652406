<template>
  <div class="wrapper mt-4">
    <b-row>
      <b-col
        lg="3"
        data-aos-delay="100"
        data-aos-duration="500"
        data-aos="zoom-out"
      >
        <a
          href="https://www.agencialopd.com/"
          target="_blank"
        >
          <img
            loading="lazy"
            src="../assets/img/marcas/RGPD-04.svg"
            style="width: 100px;"
            alt="RGPD"
          >
        </a>
      </b-col>
      <b-col
        lg="3"
        data-aos-delay="200"
        data-aos-duration="500"
        data-aos="zoom-out"
      >
        <a
          href="https://www.websincoste.com/agente-digital/"
          target="_blank"
        >
          <img
            loading="lazy"
            alt="Agente digital"
            src="../assets/img/marcas/agente_digital.svg"
            style="width: 100px;"
          >
        </a>
      </b-col>
      <b-col
        lg="3"
        data-aos-delay="300"
        data-aos-duration="500"
        data-aos="zoom-out"
      >
        <a
          href="/aicad-digital"
          target="_blank"
        >
          <img
            loading="lazy"
            alt="Aicad Digital"
            src="../assets/img/marcas/Aicad_digital.svg"
            style="width: 100px;"
          >
        </a>
      </b-col>
      <b-col
        lg="3"
        data-aos-delay="400"
        data-aos-duration="500"
        data-aos="zoom-out"
      >
        <a
          href="/business-break"
          target="_blank"
        >
          <img
            loading="lazy"
            alt="Business Break"
            src="../assets/img/marcas/Business_Break-svg.png"
            style="width: 100px;"
          >
        </a>
      </b-col>
    </b-row>
    <br>
    <br>
    <b-row>
      <b-col
        lg="3"
        data-aos-delay="500"
        data-aos-duration="500"
        data-aos="zoom-out"
      >
        <a href="/seleccion-y-reclutamiento-de-personal/">
          <img
            loading="lazy"
            alt="RRHH"
            src="../assets/img/marcas/Canal_RRHH+.svg"
            style="width: 100px;"
          >
        </a>
      </b-col>
      <b-col
        lg="3"
        data-aos-delay="600"
        data-aos-duration="500"
        data-aos="zoom-out"
      >
        <a href="/familia-segura/">
          <img
            loading="lazy"
            alt="Familia Segura"
            src="../assets/img/marcas/Familia_Segura-svg.png"
            style="width: 100px;"
          >
        </a>
      </b-col>
      <b-col
        lg="3"
        data-aos-delay="700"
        data-aos-duration="500"
        data-aos="zoom-out"
      >
        <a href="/profesional-de-confianza/">
          <img
            loading="lazy"
            src="../assets/img/marcas/Profesional_de_Confianza.svg"
            style="width: 100px;"
            alt="Profesional_de_Confianza"
          >
        </a>
      </b-col>
      <b-col
        lg="3"
        data-aos-delay="800"
        data-aos-duration="500"
        data-aos="zoom-out"
      >
        <a
          href="https://www.websincoste.com/agente-turistico/"
          target="_blank"
        >
          <img
            loading="lazy"
            alt="Agente Turistico"
            src="../assets/img/marcas/AGENTE_TURISTICO.svg"
            style="width: 100px;"
          >
        </a>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
    name: 'Marcas'
};
</script>

<style scoped lang="scss">
.wrapper {
	margin: auto;
	text-align: center;
}
h4 {
	font-family: 'Lato thin', sans-serif;
	text-align: center;
	border-right: 2px gray solid;
}
img {
	margin: 0;
	transition: 0.3s ease;
}
</style>
