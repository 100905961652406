<template>
  <div class="untitled">
    <div class="untitled__slides">
      <div class="untitled__slide">
        <div
          loading="lazy"
          alt="Cursos y MBA"
          class="untitled__slideBg"
        />
        <div class="untitled__slideContent">
          <router-link
            class="button"
            to="/convenio-de-practicas/"
          >
            {{ $t('buttons.btn2') }}
          </router-link>
        </div>
      </div>
      <div class="untitled__slide">
        <div
          loading="lazy"
          alt="Fintech"
          class="untitled__slideBg"
        />
        <div class="untitled__slideContent">
          <a
            class="button"
            href="/fintech"
          >
            {{ $t('buttons.btn5') }}
          </a>
        </div>
      </div>
      <div class="untitled__slide">
        <div
          loading="lazy"
          alt="Fundación Aicad"
          class="untitled__slideBg"
        />
        <div class="untitled__slideContent">
          <a
            class="button"
            href="/fundacion-aicad"
          >
            {{ $t('buttons.btn5') }}
          </a>
        </div>
      </div>
    </div>
    <div class="untitled__shutters" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
	$t: 2.5s;
	$e1: #009090;
	$e2: #009090;
	$er: linear;

	$color: rgba(0, 112, 168, 1);
	$font: 'Abril Fatface', sans-serif;

	$slides: 3;
	$images: url(../assets/img/slides/cursos_y_mbas1.jpg) url(../assets/img/slides/banner-fintech.jpg) url(../assets/img/slides/banner-fundacion.jpg);
	$images-md: url(../assets/img/slides/cursos_y_mbas_1sm.jpg) url(../assets/img/slides/banner-fintech-sm.jpg) url(../assets/img/slides/banner-fundacion-sm.jpg);
	$images-sm: url(../assets/img/slides/cursos_y_mbas_1sm.jpg) url(../assets/img/slides/banner-fintech-sm.jpg) url(../assets/img/slides/banner-fundacion-sm.jpg);

	html,
body {
}


.untitled {
	position: relative;
	height: 100%;
	width: 100%;
	animation: rotateHue infinite $t * $slides * 2 linear;
	animation-delay: $t/4;

	&__shutters {
		position: absolute;
		height: 150vmax;
		width: 150vmax;
		left: calc(50% - 75vmax);
		top: calc(50% - 75vmax);
		pointer-events: none;
		z-index: 2;
		@keyframes rotateFrame {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(180deg);
			}
		}
		animation: rotateFrame $t * 4 $er infinite;

		&:before,
		&:after {
			content: '';
			position: absolute;
			height: 100%;
			width: 100%;
			left: 50%;
			transform: translate3d(-50%, 0, 0);
			background: $color;
			pointer-events: auto;
		}
		&:before {
			bottom: 50%;
			@keyframes openTop {
				0% {
					transform: translate3d(-50%, 0, 0);
					animation-timing-function: $e1;
				}
				40% {
					transform: translate3d(-50%, -65vmax, 0);
					animation-timing-functon: $e2;
				}
				70% {
					transform: translate3d(-50%, -65vmax, 0);
					animation-timing-functon: $e2;
				}
				100% {
					transform: translate3d(-50%, 0, 0);
					animation-timing-function: $e2;
				}
			}
			animation: openTop $t * 2 infinite;
		}
		&:after {
			top: 50%;
			@keyframes openBottom {
				0% {
					transform: translate3d(-50%, 0, 0);
					animation-timing-function: $e1;
				}
				40% {
					transform: translate3d(-50%, 65vmax, 0);
					animation-timing-functon: $e2;
				}
				70% {
					transform: translate3d(-50%, 65vmax, 0);
					animation-timing-functon: $e2;
				}
				100% {
					transform: translate3d(-50%, 0, 0);
					animation-timing-function: $e2;
				}
			}
			animation: openBottom $t * 2 infinite;
		}
	}

	&__slides {
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		background: $color;
	}
	&__slide {
		position: absolute;
		height: 100%;
		width: 100%;
		opacity: 0;
		@keyframes showHideSlide {
			0% {
				opacity: 1;
				pointer-events: auto;
				z-index: 1;
			}
			#{100% / $slides} {
				opacity: 0;
				pointer-events: none;
				z-index: -1;
			}
			100% {
				opacity: 0;
				pointer-events: none;
				z-index: -1;
			}
		}
		animation: showHideSlide infinite $t * $slides * 2 steps(1);
		@media only screen and (min-width: 1050px) {
			@for $i from 1 through $slides {
				&:nth-child(#{$i}) {
					animation-delay: $t * ($i - 1) * 2;
					.untitled__slideBg {
						background-image: nth($images, $i);
					}
				}
			}
		}
		@media only screen and (max-width: 1049px) and (min-width: 763px) {
			@for $i from 1 through $slides {
				&:nth-child(#{$i}) {
					animation-delay: $t * ($i - 1) * 2;
					.untitled__slideBg {
						background-image: nth($images-md, $i);
					}
				}
			}
		}
		@media only screen and (max-width: 764px) {
			@for $i from 1 through $slides {
				&:nth-child(#{$i}) {
					animation-delay: $t * ($i - 1) * 2;
					.untitled__slideBg {
						background-image: nth($images-sm, $i);
					}
				}
			}
		}
	}
	&__slideBg {
		position: relative;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		background-size: cover;
		background-position: center;
		background-blend-mode: hard-light;
		opacity: 1;
		z-index: -1;
	}
	&__slideContent {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
		color: white;
		font-family: $font;
		line-height: 0.8;
		letter-spacing: -0.025em;
		z-index: 2;
		opacity: 1;
		text-shadow: 0 0 0.5em rgba(#009090, 0.25);
		mix-blend-mode: lighten;
		span {
			display: block;
			font-size: 15vmin;
		}
	}
}
.button {
	font-family: 'Roboto Mono', sans-serif;
	text-decoration: none;
	font-weight: 800;
	text-transform: uppercase;
	font-size: 4vmin;
	display: inline-block;
	position: relative;
	border: 3px solid white;
	box-shadow: -0.5vmin 0.5vmin 0 rgba(white, 0.5);
	background: transparent;
	margin-top: 30vmin;
	mix-blend-mode: lighten;
	color: white;
	padding: 2vmin 2vmin 1.8vmin 2vmin;
	letter-spacing: 0.1em;
	text-shadow: none;
	line-height: 1;
	transform: translate3d(0.5vmin, -0.5vmin, 0);
	transition: all 100ms linear;
	&:hover {
		transform: translate3d(1vmin, -1vmin, 0);
		box-shadow: -1vmin 1vmin 0 rgba(white, 0.5);
		background: white;
		color: #009090;
	}
	&:active {
		transform: translate3d(0px, 0px, 0);
		box-shadow: -0px 0px 0 rgba(white, 0.5);
	}
}

@media only screen and (max-width: 600px) {
	.button {
		background-color: lightblue;
		margin-top: 200px;
	}
}
</style>
