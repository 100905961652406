<template>
  <div>
    <div class="loller">
      <Seperator />
      <Home_slider />
    </div>
    <Seperator />
    <div class="lol">
      <Devider />
    </div>
    <Franquicias />
    <Mapper />
    <div class="skew-btm-whitesmoke" />
  </div>
</template>

<script>
import Home_slider from '@/components/Home_slider.vue';
import Seperator from '@/components/Reusable/Seperator.vue';
import Devider from '@/components/Devider.vue';
import Franquicias from '@/components/Franquicias';
import Mapper from '@/components/Map.vue';

export default {
    components: {
        Home_slider,
        Seperator,
        Devider,
        Franquicias,
        Mapper
    },
    metaInfo() {
        return {
            title: this.$t('meta.aicad.title'),
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.$t('meta.aicad.description')
                }
            ]
        };
    }
};
</script>

<style scoped lang="scss">
.skew-btm-green {
	width: 100%;
	height: 100px;
	position: absolute;
	left: 0;
	background: linear-gradient(to left bottom, #009090 49%, transparent 50%);
}
.skew-btm-whitesmoke {
	width: 100%;
	height: 100px;
	position: absolute;
	left: 0;
	background: linear-gradient(to left bottom, #4e4e4e 49%, transparent 50%);
}
.lol {
	margin-top: -20px;
}

.loller {
	width: 100%;
	height: 50vh;
	overflow: hidden;
}

.lollerr {
	width: 100%;
	overflow: hidden;
}
@media (max-width: 425px){
	.loller {
		height: 250px !important;
	}
}

</style>
