import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: '',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/practicas-en-empresas',
            name: 'resume',
            component: () => import('./views/PracticasEnEmpresas/PracticasEnEmpresas')
        },
        {
            path: '/conoce-aicad',
            name: 'definition',
            component: () => import('./views/Definition.vue')
        },
        {
            path: '/conoce-aicad/mision',
            name: 'definition-mision',
            component: () => import('./views/Definition.vue')
        },
        {
            path: '/conoce-aicad/vision',
            name: 'definition-vision',
            component: () => import('./views/Definition.vue')
        },
        {
            path: '/conoce-aicad/valores',
            name: 'definition-valores',
            component: () => import('./views/Definition.vue')
        },
        {
            path: '/conoce-aicad/cultura',
            name: 'definition-cultura',
            component: () => import('./views/Definition.vue')
        },
        {
            path: '/conoce-aicad/filosofia',
            name: 'definition-filosofia',
            component: () => import('./views/Definition.vue')
        },
        {
            path: '/conoce-aicad/areas',
            name: 'definition-areas',
            component: () => import('./views/Definition.vue')
        },
        {
            path: '/alma-digital',
            name: 'alma-digital',
            component: () => import('./views/Alma.vue')
        },
        {
            path: '/alma-digital/transformacion-digital',
            name: 'transformacion-digital',
            component: () => import('./views/Alma-2.vue')
        },
        {
            path: '/alma-digital/digital-sales-journey',
            name: 'digital-sales-journey',
            component: () => import('./views/Alma-2.vue')
        },
        {
            path: '/alma-digital/digital-strategy-priority',
            name: 'digital-strategy-priority',
            component: () => import('./views/Alma-2.vue')
        },
        {
            path: '/alma-digital/digital-sales-info',
            name: 'digital-sales',
            component: () => import('./views/Alma-2.vue')
        },
        {
            path: '/alma-digital/compentencias-digitales',
            name: 'compentencias-digitales',
            component: () => import('./views/Alma-2.vue')
        },
        {
            path: '/head-hunting',
            name: 'head-hunting',
            component: () => import('./views/HeadHunting.vue')
        },
        {
            path: '/we-are-active-in',
            name: 'we-are-active-in',
            component: () => import('./views/WeAreActiveIn.vue')
        },
        {
            path: '/ecosistema-de-innovacion',
            name: 'ecosistema-de-innovacion',
            component: () => import('./views/Ecosistema.vue')
        },
        {
            path: '/areas-de-actividad',
            name: 'areas-de-actividad',
            component: () => import('./views/Areas.vue')
        },
        {
            path: '/proveedor-global',
            name: 'proveedor-global',
            component: () => import('./views/Proveedor.vue')
        },
        {
            path: '/campus',
            name: 'campus',
            component: () => import('./views/Campus.vue')
        },
        {
            path: '/marcas',
            name: 'marcas',
            component: () => import('./views/Marcas.vue')
        },
        {
            path: '/proyectos',
            name: 'proyectos',
            component: () => import('./views/Proyectos.vue')
        },
        {
            path: '/escuela-de-idiomas',
            name: 'escuela-de-idiomas',
            component: () => import('./views/Idiomas.vue')
        },
        {
            path: '/quieres-seducir-a-tus-clientes',
            name: 'education',
            component: () => import('./views/Education/Education.vue')
        },
        {
            path: '/suenas-con-captar-clientes-online-y-no-lo-consigues',
            name: 'education2',
            component: () => import('./views/Education/Suenas.vue')
        },
        {
            path: '/business-and-tech',
            name: 'business',
            component: () => import('./views/Home_cards/Business.vue')
        },
        {
            path: '/economia-digital',
            name: 'economia',
            component: () => import('./views/Home_cards/Economia.vue')
        },
        {
            path: '/aicad-digital',
            name: 'Contenido',
            component: () => import('./views/Aicad_digital/Contenido.vue')
        },
        {
            path: '/soluciones-web',
            name: 'Web',
            component: () => import('./views/Aicad_digital/Web.vue')
        },
        {
            path: '/video-produccion',
            name: 'Video',
            component: () => import('./views/Aicad_digital/Video.vue')
        },
        {
            path: '/convenio-de-practicas',
            name: 'ConvenioPracticas',
            component: () => import('./views/ConvenioPracticas.vue')
        },
        {
            path: '/practicas-en-empresa-master-talento',
            name: 'MasterTalento',
            component: () => import('./views/Practica/MasterTalento.vue')
        },
        {
            path: '/formacion-bonificada',
            name: 'FormacionBonificada',
            component: () => import('./views/FormacionBonificada.vue')
        },
        {
            path: '/consultoria-empresarial',
            name: 'ConsultoriaEmpresarial',
            component: () => import('./views/Servicios/ConsultoriaEmpresarial.vue')
        },
        {
            path: '/profesional-de-confianza',
            name: 'ProfesionalConfienza',
            component: () => import('./views/ProfesionalConfienza.vue')
        },
        {
            path: '/delegaciones',
            name: 'Franquicias',
            component: () => import('./views/Franquicias.vue')
        },
        {
            path: '/agente-turistico',
            name: 'AgenteTuristico',
            component: () => import('./views/AgenteTuristico.vue')
        },
        {
            path: '/practicas-alumnos',
            name: 'PracticasAlumnos',
            component: () => import('./views/Practica/PracicasAlumnos.vue')
        },
        {
            path: '/familia-segura',
            name: 'FamiliaSegura',
            component: () => import('./views/FamiliaSegura.vue')
        },
        {
            path: '/contacta-con-nosotros',
            name: 'Contact',
            component: () => import('./views/Contact.vue')
        },
        {
            path: '/business-break',
            name: 'BusinessBreak',
            component: () => import('./views/BusinessBreak.vue')
        },
        {
            path: '/franquicias-de-exito',
            name: 'Exito',
            component: () => import('./views/Exito.vue')
        },
        {
            path: '/testimonios-de-clientes',
            name: 'Testimonos',
            component: () => import('./views/Testimonos.vue')
        },
        {
            path: '/socio-director-franquiciado',
            name: 'DirectorFranquiciado',
            component: () => import('./views/Servicios/DirectorFranquiciado.vue')
        },
        {
            path: '/inversores',
            name: 'Inversores',
            component: () => import('./views/Inversores.vue')
        },
        {
            path: '/barcelona-centro',
            name: 'Country',
            component: () => import('./views/Countries/Barcelona.vue')
        },
        {
            path: '/madrid',
            name: 'Madrid',
            component: () => import('./views/Countries/Madrid.vue')
        },
        {
            path: '/larioja',
            name: 'Larioja',
            component: () => import('./views/Countries/Larioja.vue')
        },
        {
            path: '/rioja-navarra',
            name: 'RiojaNavarra',
            component: () => import('./views/Countries/RiojaNavarra.vue')
        },
        {
            path: '/castellon',
            name: 'Castellon',
            component: () => import('./views/Countries/Castellon.vue')
        },
        {
            path: '/granada',
            name: 'Granada',
            component: () => import('./views/Countries/Granada.vue')
        },
        {
            path: '/leganes',
            name: 'Leganes',
            component: () => import('./views/Countries/Leganes.vue')
        },
        {
            path: '/juan-bravo',
            name: 'JuanBravo',
            component: () => import('./views/Countries/JuanBravo.vue')
        },
        {
            path: '/dusseldorf-germany',
            name: 'Dusseldorf',
            component: () => import('./views/Countries/Dusseldorf.vue')
        },
        {
            path: '/lugones',
            name: 'Lugones',
            component: () => import('./views/Countries/Lugones.vue')
        },
        {
            path: '/cuenca',
            name: 'Cuenca',
            component: () => import('./views/Countries/Cuenca.vue')
        },
        {
            path: '/cordoba',
            name: 'Cordoba',
            component: () => import('./views/Countries/Cordoba.vue')
        },
        {
            path: '/alicante',
            name: 'Alicante',
            component: () => import('./views/Countries/Alicante.vue')
        },
        {
            path: '/malaga',
            name: 'Malaga',
            component: () => import('./views/Countries/Malaga.vue')
        },
        {
            path: '/valladolid',
            name: 'Valladolid',
            component: () => import('./views/Countries/Valladolid.vue')
        },
        {
            path: '/coruna',
            name: 'Acoruna',
            component: () => import('./views/Countries/Acoruna.vue')
        },
        {
            path: '/tenerife',
            name: 'Tenerife',
            component: () => import('./views/Countries/Tenerife.vue')
        },
        {
            path: '/bilbao',
            name: 'Bilbao',
            component: () => import('./views/Countries/Bilbao.vue')
        },
        {
            path: '/vigo',
            name: 'Vigo',
            component: () => import('./views/Countries/Vigo.vue')
        },
        {
            path: '/ibiza',
            name: 'Ibiza',
            component: () => import('./views/Countries/Ibiza.vue')
        },
        {
            path: '/zaragoza',
            name: 'Zaragoza',
            component: () => import('./views/Countries/Zaragoza.vue')
        },
        {
            path: '/germany',
            name: 'Germany',
            component: () => import('./views/HeadHunting/Germany.vue')
        },
        {
            path: '/france',
            name: 'France',
            component: () => import('./views/HeadHunting/France.vue')
        },
        {
            path: '/switzerland',
            name: 'Switzerland',
            component: () => import('./views/HeadHunting/Switzerland.vue')
        },
        {
            path: '/uk',
            name: 'UK',
            component: () => import('./views/HeadHunting/UK.vue')
        },
        {
            path: '/austria',
            name: 'Austria',
            component: () => import('./views/HeadHunting/Austria.vue')
        },
        {
            path: '/ireland',
            name: 'Ireland',
            component: () => import('./views/HeadHunting/Ireland.vue')
        },
        {
            path: '/belgium',
            name: 'Belgium',
            component: () => import('./views/HeadHunting/Belgium.vue')
        },
        {
            path: '/luxemburg',
            name: 'Luxemburg',
            component: () => import('./views/HeadHunting/Luxemburg.vue')
        },
        {
            path: '/sweden',
            name: 'Sweden',
            component: () => import('./views/HeadHunting/Sweden.vue')
        },
        {
            path: '/norway',
            name: 'Norway',
            component: () => import('./views/HeadHunting/Norway.vue')
        },
        {
            path: '/denmark',
            name: 'Denmark',
            component: () => import('./views/HeadHunting/Denmark.vue')
        },
        {
            path: '/spain',
            name: 'Spain',
            component: () => import('./views/HeadHunting/Spain.vue')
        },
        {
            path: '/barcelona',
            name: 'Barcelona',
            component: () => import('./views/Barcelona/Barcelona.vue')
        },
        {
            path: '/barcelona/ven-a-conocernos',
            name: 'BarcelonaOpenDay',
            component: () => import('./views/Barcelona/BarcelonaOpenDay.vue')
        },
        {
            path: '/barcelona/proceso-admision',
            name: 'BarcelonaProcesoAdmission',
            component: () => import('./views/Barcelona/BarcelonaProcesoAdmission.vue')
        },
        {
            path: '/masteres-posgrados',
            name: 'MasteresPosgrados',
            component: () => import('./views/MasteresPosgrados/MasteresPosgrados.vue')
        },
        {
            path: '/masteres-posgrados/ven-a-conocernos',
            name: 'MasteresPosgradosOpenDay',
            component: () => import('./views/MasteresPosgrados/MasteresPosgradosOpenDay.vue')
        },
        {
            path: '/masteres-posgrados/proceso-admision',
            name: 'MasteresPosgradosProcesoAdmission',
            component: () => import('./views/MasteresPosgrados/MasteresPosgradosProcesoAdmission.vue')
        },
        {
            path: '/incorpora-talento',
            name: 'IncorporaTalento',
            component: () => import('./views/IncorporaTalento/IncorporaTalento.vue')
        },
        {
            path: '/cursos-para-militares',
            name: 'CursosMilitaries',
            component: () => import('./views/CursosMilitaries/CursosMilitaries.vue')
        },
        {
            path: '/cursos-para-militares/detalle',
            redirect: { name: 'CursosMilitaries' }
        },
        {
            path: '/cursos-para-militares/detalle/:alias',
            name: 'CursosMilitariesDetalle',
            component: () => import('./views/CursosMilitaries/CursosMilitariesDetalle.vue')
        },
        {
            path: '/homologacion',
            name: 'Homologacion',
            component: () => import('./views/Homologacion/Homologacion.vue')
        },
        {
            path: '/practicas-garantizadas',
            name: 'PracticasGarantizadas',
            component: () => import('./views/PracticasGarantizadas/PracticasGarantizadas.vue')
        },
        {
            path: '/responsabilidad-social',
            name: 'ResponsabilidadSocial',
            component: () => import('./views/ResponsabilidadSocial/ResponsabilidadSocial.vue')
        },
        {
            path: '/escuelas-corporativas',
            name: 'EscuelasCorporativas',
            component: () => import('./views/EscuelasCorporativas/EscuelasCorporativas.vue')
        },
        {
            path: '/escuelas-corporativas/introducción',
            redirect: { name: 'EscuelasCorporativas' }
        },
        {
            path: '/escuelas-corporativas/digital',
            name: 'EscuelasCorporativasDigital',
            component: () => import('./views/EscuelasCorporativas/EscuelasCorporativasDigital.vue')
        },
        {
            path: '/escuelas-corporativas/ventas',
            name: 'EscuelasCorporativasVentas',
            component: () => import('./views/EscuelasCorporativas/EscuelasCorporativasVentas.vue')
        },
        {
            path: '/escuelas-corporativas/marketing',
            name: 'EscuelasCorporativasMarketing',
            component: () => import('./views/EscuelasCorporativas/EscuelasCorporativasMarketing.vue')
        },
        {
            path: '/estrategia-digital',
            name: 'EstrategiaDigital',
            component: () => import('./views/EstrategiaDigital/EstrategiaDigital.vue')
        }
    ],
    // eslint-disable-next-line no-unused-vars
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 };
    }
});
