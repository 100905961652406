<template>
  <body>
    <div class="container financiacion">
      <h2>
        Financiación y
      </h2>
      <h2 style="color:#0D9190">
        Acreditaciones
      </h2>
      <div class="financiacion-info">
        <p>¿Quieres pertenecer a nuestra red de centros asociados y crecer con nosotros?</p>
        <div class="divider mt-4 mb-4" />
        <p>Contamos con instituciones de prestigio para  facilitarte financiación, subvenciones y ayudas para el desarrollo de tu negocio.</p>
      </div>
    </div>
    <div class="container">
      <div class="row logos">
        <div class="col-md-3 col-xs-12">
          <a
            href="/profesional-de-confianza/"
            target="_blank"
          >
            <img
              loading="lazy"
              alt="Profesional de confianza"
              src="../assets/img/profesional_de_confianza.png"
            >
          </a>
          <div style="width:100%">
            <p>Embajadores, consultores de relevancia, agentes de éxito profesional. Encuentra en AICAD tu mejor alianza para crecer en digital.</p>
            <p class="link">
              <a
                href="/profesional-de-confianza/"
                target="_blank"
              > {{ $t('buttons.btn5') }} </a>
            </p>
          </div>
        </div>
        <div class="col-md-3 col-xs-12">
          <a
            href="/bbva-y-aicad-business-school-firman-un-acuerdo"
            target="_blank"
          >
            <img
              loading="lazy"
              alt="BBVA"
              src="../assets/img/bbvav2.jpg"
            >
          </a>
          <div style="width:100%">
            <p>AICAD Business School ha firmado un convenio global estratégico con el BBVA, para  nuevas sedes de franquicias que busquen financiación.</p>
            <p class="link">
              <a
                href="/bbva-y-aicad-business-school-firman-un-acuerdo/"
                target="_blank"
              > {{ $t('buttons.btn5') }} </a>
            </p>
          </div>
        </div>
        <div class="col-md-3 col-xs-12">
          <a
            href="https://www.ico.es/web/ico/sobre-ico"
            target="_blank"
          >
            <img
              loading="lazy"
              alt="ico"
              src="../assets/img/ico.png"
            >
          </a>
          <div style="width:100%">
            <p>Si tienes una idea con potencial de apoyo del ICO, Únete a la Red de Escuelas de Negocios y Tecnología de Educación Superior líder de España.</p>
            <p class="link">
              <a
                href="https://www.ico.es/web/ico/sobre-ico"
                target="_blank"
                rel="nofollow"
              > {{ $t('buttons.btn5') }} </a>
            </p>
          </div>
        </div>
        <div class="col-md-3 col-xs-12">
          <a
            href="https://red.es/redes/"
            target="_blank"
            rel="nofollow"
          >
            <img
              loading="lazy"
              alt="redes"
              src="../assets/img/redes.png"
            >
          </a>
          <div style="width:100%">
            <p>Transformación digital AICAD se suma al Proyecto Acelera Pyme, promovido por Red.es para acelerar a Pymes y autónomos tras la COVID-19</p>
            <p class="link">
              <a
                href="https://red.es/redes/"
                target="_blank"
                rel="nofollow"
              >{{ $t('buttons.btn5') }}</a>
            </p>
          </div>
        </div>
      </div>
      <div class="row logos-nav">
        <div class="col-md-3">
          <p>
            <a
              href="/profesional-de-confianza/"
              target="_blank"
            >{{ $t('buttons.btn5') }}</a>
          </p>
        </div>
        <div class="col-md-3">
          <p>
            <a
              href="/bbva-y-aicad-business-school-firman-un-acuerdo/"
              target="_blank"
            >{{ $t('buttons.btn5') }}</a>
          </p>
        </div>
        <div class="col-md-3">
          <p>
            <a
              href="https://www.ico.es/web/ico/sobre-ico"
              target="_blank"
              rel="nofollow"
            >{{ $t('buttons.btn5') }}</a>
          </p>
        </div>
        <div class="col-md-3">
          <p>
            <a
              href="https://red.es/redes/"
              target="_blank"
              rel="nofollow"
            >{{ $t('buttons.btn5') }}</a>
          </p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="masteres-card-wrapper row">
        <div class="col-md-4 col-xs-4 titulo-item">
          <div class="item-info">
            <div
              class="item item1"
              loading="lazy"
            />
            <div class="title">
              <h2>
                TÍTULOS
              </h2>
              <h3>
                UNIVERSITARIOS
              </h3>
            </div>
            <div class="info">
              <p>
                Acuerdos internacionales, Titulaciones Oficiales, Administración de empresas digitales, Management, Marketing, Estrategias de Mercado, organización, economía digital.
                <label>
                  <a
                    href="/masteres-universitarios/"
                    target="_blank"
                  >
                    {{ $t('buttons.btn5') }}
                  </a>
                </label>
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-xs-4 titulo-item">
          <div class="item-info">
            <div
              class="item item2"
              loading="lazy"
            />
            <div class="title">
              <h2>
                Cursos
              </h2>
              <h3>
                y Posgrados
              </h3>
            </div>
            <div class="info">
              <p>
                Innovación educativa 360 en MBAs, Másteres, Postgrados y cursos de especialización en Marketing, Tecnología, Ventas, Negociación Internacional Business, Recursos Humanos, Logística , Banca y Finanzas.
                <label>
                  <a
                    href="/cursos-online/masteres-posgrados/"
                    target="_blank"
                  >
                    {{ $t('buttons.btn5') }}
                  </a>
                </label>
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-xs-4 titulo-item">
          <div class="item-info">
            <div 
              class="item item3"
              loading="lazy"
            />
            <div class="title">
              <h2>
                Executive
              </h2>
              <h3>
                Education
              </h3>
            </div>
            <div class="info">
              <p>
                Los MBAs más punteros en gestión integral de negocios. Programas intensivos de alta tendencia empresarial orientados al mundo digital, a emprendedores y profesionales que buscan nuevos desafios.
                <label>
                  <a
                    href="/fintech/"
                    target="_blank"
                  >
                    {{ $t('buttons.btn5') }}
                  </a>
                </label>
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-xs-4 titulo-item">
          <div class="item-info">
            <div 
              class="item item4"
              loading="lazy"
            />
            <div class="title">
              <h2>
                CORPORATE
              </h2>
              <h3>
                EDUCATION
              </h3>
            </div>
            <div class="info">
              <p>
                Proyectos corporativos de formación a medida para empresas. Metodología diseñada para equipos directivos, venta digital, reciclaje de competencias y aprendizaje de nuevas áreas de conocimiento.
                <label>
                  <a
                    href="/escuelas-corporativas/"
                    target="_blank"
                  >
                    {{ $t('buttons.btn5') }}
                  </a>
                </label>
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-xs-4 titulo-item">
          <div class="item-info">
            <div 
              class="item item5"
              loading="lazy"
            />
            <div class="title">
              <h2>
                PRÁCTICAS
              </h2>
              <h3>
                INTERNACIONALES
              </h3>
            </div>
            <div class="info">
              <p>
                Inserción laboral a los nuevos profesionales que van a liderar el mundo de los próximos 10 años, Prácticas en empresas curriculares y extracurriculares para alumnos de posgrado, sin fronteras, sin barreras a un click.
                <label>
                  <a
                    href="/practicas/empresas-erasmus/"
                    target="_blank"
                  >
                    {{ $t('buttons.btn5') }}
                  </a>
                </label>
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-xs-4 titulo-item">
          <div class="item-info">
            <div 
              class="item item6"
              loading="lazy"
            />
            <div class="title">
              <h2>
                PORTAL
              </h2>
              <h3>
                DE EMPLEO
              </h3>
            </div>
            <div class="info">
              <p>
                Te presentamos la revolución del empleo. Portal con inteligencia artificial dónde pueden hacer match candidatos, empresas y alinear sus valores. El portal es el aliado para directores de Recursos Humanos y Headhunters.
                <label>
                  <a
                    href="https://planetajob.es/"
                    target="_blank"
                    rel="nofollow"
                  >
                    {{ $t('buttons.btn5') }}
                  </a>
                </label>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="colour-block"
      style="padding-top:0"
    >
      <h2>
        {{ $t('cards.title') }}
      </h2>
      <h3 style="margin-bottom: 50px;">
        {{ $t('cards.number') }}
      </h3>
      <Home_cards />
    </div>
    <div
      class="white-block"
      style=""
    >
      <h2 style="margin-top: 20px;">
        {{ $t('academics.big-title') }}
      </h2>
      <Seperator />
      <Home_menu />
    </div>
    <div class="skew-c" />
    <div class="last-block">
      <h2
        style="color: #c7c7c7;"
        data-aos-delay="100"
        data-aos="fade-up"
      >
        {{ $t('news.big-title') }}
      </h2>
      <Arrow />
      <Team />
    </div>
    <Seperator />
    <h2 style="margin-top: 100px; margin-bottom: 20px;">
      <img
        loading="lazy"
        alt="icon"
        src="../assets/img/icons/lupa-executive2.png"
      >
      {{ $t('message.enucentro_de') }}<span style="color: #009090"> {{ $t('message.professionals') }}</span>
    </h2>
    <Home_encuerto />
    <div class="skew-green" />
    <div class="green-block mb-5">
      <h2
        data-aos-delay="100"
        data-aos="fade-up"
        class="text-white"
      >
        <img
          loading="lazy"
          alt="icon"
          src="../assets/img/icons/Innovacion-radical1.png"
          class="img-responsive"
        >
        {{ $t('message.soluciones_llave') }}
      </h2>
      <Home_soluciones />
    </div>
    <div class="skew-btm-green" />
    <div class="skew-grey" />
    <div class="smoke-block">
      <h2
        data-aos-delay="100"
        data-aos="fade-up"
        class="text-white"
      >
        <img
          loading="lazy"
          alt="icon"
          src="../assets/img/icons/Innovacion-radical2.png"
          class="img-responsive"
        >
        {{ $t('message.radical_innovation') }}
      </h2>
      <Home_radical />
    </div>
    <div class="skew-btm" />
    <div
      class="hidden-sm-down"
      style="margin-top: 100px;"
    >
      <h2>
        <img
          loading="lazy"
          alt="icon"
          src="../assets/img/icons/icono-15.png"
        >
        {{ $t('message.live_experience') }}
        <span style="color: #009090">{{ $t('message.AICAD') }}</span>
      </h2>
      <Home_noticias />
    </div>
    <div class="skew-cc-marcas" />
    <div
      class="white-block"
      style="margin-bottom: -50px;"
    >
      <h2
        data-aos-delay="200"
        data-aos-duration="100"
        data-aos="zoom-in"
      >
        {{ $t('message.Nuestras') }}<span style="color: #009090">{{ $t('message.MARCAS') }}</span>
      </h2>
      <Marcas />
    </div>
    <div class="skew-btm-whitesmoke hidden-sm-down" />
  </body>
</template>

<script>
import Home_cards from '@/components/Home_cards.vue';
import Home_menu from '@/components/Home_menu.vue';
import Arrow from '@/components/Reusable/Arrow.vue';
import Seperator from '@/components/Reusable/Seperator.vue';
import Team from '@/components/Reusable/Team.vue';
import Home_encuerto from '@/components/Home_encuerto.vue';
import Home_soluciones from '@/components/Home_soluciones.vue';
import Home_radical from '@/components/Home_radical.vue';
import Home_noticias from '@/components/Home_noticias.vue';
import Marcas from '@/components/Marcas';

export default {
    name: 'Devider',
    components: {
        Home_cards,
        Home_menu,
        Arrow,
        Seperator,
        Team,
        Home_encuerto,
        Home_soluciones,
        Home_radical,
        Home_noticias,
        Marcas
    }
};
</script>

<style lang="scss">
@media screen and (max-width: 770px) {
  .hidden-sm-down {
    display: none !important;
  }
}
.item-info {
	display: flex;
	flex-direction: column;
	padding: 0;
}
.info {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	transition: all 0.3s ease;
}
.info:after {
	background-color: #fff;
	opacity: 0.6;
	content: ' ';
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	position: absolute;
}
.item-info:hover .info {
	opacity: 1;
}
.masteres-card-wrapper {
	padding: 2rem 0;
	background-color: #fff;
}
.item {
	height: 50vh;
	width: 100%;
	background-size: cover;
	background-position: top center;
	background-repeat: no-repeat;
}
.divider {
	background-image: url('../assets/img/divider.png');
	height: 3px;
	background-position: center center;
	background-size: 100%;
}
.item-info div p a {
	margin-top: 15px;
	background: #0d9190;
	border-radius: 12px;
	padding: 7px 20px;
	color: #fff !important;
}
.item-info div p label {
	margin-top: 20px;
	display: block;
}
.item-info div p label a {
	cursor: pointer;
}
.item-info div p label a:hover {
	background: #075251;
}

.item1 {
	background-image: url('../assets/img/titulov2/titulos-universitarios.jpg');
}
.item2 {
	background-image: url('../assets/img/titulov2/master-postgrado.jpg');
}
.item3 {
	background-image: url('../assets/img/titulov2/mbas-postgrado.jpg');
}
.item4 {
	background-image: url('../assets/img/titulov2/corporate-education.jpg');
}
.item5 {
	background-image: url('../assets/img/titulov2/online-formacion.jpg');
}
.item6 {
	background-image: url('../assets/img/titulov2/portal.jpg');
}

.logos .col-md-3 {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}
.logos .col-md-3 p {
	font-size: 1rem;
	text-align: center;
}
.item-info h2,
.item-info h3 {
	font-family: Kelson, sans-serif;
	color: #fff;
}
.financiacion {
	padding: 5rem 0 3rem 0;
}
.financiacion p {
	font-size: 1rem;
}
.financiacion {
	text-align: center;
}
.financiacion h2,
.financiacion h3 {
	display: inline-block;
	padding: 1rem 0;
	margin-right: 20px;
	font-family: Lato;
}
.financiacion h2 span{
  color:#0D9190;
}
.financiacion-info {
	width: 50%;
	margin: 0 auto;
}
.financiacion-info p {
	font-weight: bold;
}

.container {
  margin-bottom: 1%;
  padding-left: 0px;
}

.container.text-group {
	display: flex;
}
.container.text-group div {
	width: 25%;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}
.container.text-group div p {
	font-size: 1rem;
	padding: 1rem;
	margin-bottom: 10px;
}
.titulo-item {
	padding: 0 !important;
}
.item-info div p {
	font-size: 0.85rem;
	text-align: center;
	z-index: 3;
	font-weight: bold;
	opacity: 1;
	color: #000;
	padding: 2rem;
	margin-top: 15%;
}
.item-info h2,
.item-info h3 {
	font-family: 'LatoWebBlack', sans-serif;
	color: #cccccc;
	font-size: 1.3rem;
}
.item-info h2 {
	margin-top: 15px;
}
.link-desktop {
	text-align: center;
}

.link-desktop a {
	cursor: pointer;
	color: #000;
}
.link-desktop a:hover {
	text-decoration: underline;
}
.logos-nav p a {
	border-radius: 16px;
	border: 2px solid #0d9190;
	padding: 7px 20px;
	color: #000;
}
.logos-nav p {
	text-align: center;
}
.logos-nav p a:hover {
	background-color: #0d9190;
	color: #fff !important;
	cursor: pointer;
}
@media (max-width: 768px) {
	.logos-nav {
		display: none !important;
	}
	.link-desktop {
		display: none;
	}
	.financiacion {
		padding: 3rem 1rem;
	}
	.item {
		height: 65vh;
	}
	.container.container-fluid.masteres-card-wrapper {
		display: flex;
		flex-direction: column;
	}
	.titulo-item {
		width: 100%;
	}
	.container.text-group div {
		width: 100%;
	}
	.container.text-group {
		flex-direction: column;
	}
	.row.logos {
		background-color: #ffff;
	}
	.logos-nav div {
		display: block;
	}
}
@media (min-width: 769px) {
	.link {
		display: none;
	}
}
$pri: #ffffff;
.img-group img {
	width: 200px;
}
.img-group.info-item {
	justify-content: space-evenly;
}
.financiacion-convenio {
	background: rgba(50, 50, 50, 50);
	color: #fff;
	padding: 5rem;
}
.financiacion-convenio .info-item {
	display: flex;
	align-items: center;
	justify-content: center;
}
.info-item p {
	font-size: 16px;
	font-family: 'Lato', sans-serif;
}
.info-item .info-item-title {
	font-size: 32px;
}
.aicad-bbva.container img {
	width: 200px;
}

.aicad-bbva.container {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 3rem;
}
@media (max-width: 768px) {
	.financiacion-convenio {
		padding: 2rem;
	}
	.info-item p {
		width: 100%;
	}
	.financiacion-convenio .info-item:nth-child(2) {
		flex-direction: column;
	}
}
.lol {
  
}

body {
	margin: 0px;
	background-color: transparent !important;
}

.colour-block {
	background: $pri;
	width: 100%;
	padding: 3% 20% 1% 20%;
	color: black;
}


@media only screen and (max-width: 1049px) and (min-width: 763px){
  .colour-block {
    display: none !important;
  }
}

.white-block {
	width: 100%;
	padding: 2% 20% 5% 20%;
	color: black;
	background-color: whitesmoke;
	background-size: auto;
	@media only screen and (max-width: 950px) {
		padding: 0;
	}
}

.smoke-block {
	width: 100%;
	color: black;
	background: #4e4e4e;
	background-size: cover;

  @media screen and (max-width: 770px) {
    padding-bottom: 12%;
  }

}

.white-block img {
  @media screen and (max-width: 770px) {
    padding-bottom: 6%;
  }
}

.last-block {
	background: #ececec;
	width: 100%;
	padding: 3% 20% 1% 20%;
	color: black;
}

.green-block {
	margin-top: 100px;
	background: #009090;
	width: 100%;
	color: black;
}

h2 {
	text-transform: uppercase;
	text-align: center;
	font-size: 2rem;
	font-family: 'LatoWebBlack', sans-serif;
	color: #cccccc;
}

h3 {
	text-transform: uppercase;
	text-align: center;
	font-size: 1.5rem;
	font-family: 'LatoWebBlack', sans-serif;
	color: rgba(23, 74, 95, 0.8);
}

p {
	font-size: 20px;
	font-family: 'Raleway', sans-serif;
}

.skew-cc {
	width: 100%;
	height: 100px;
	position: absolute;
	left: 0px;
	background: linear-gradient(to right bottom, $pri 49%, transparent 50%), linear-gradient(-50deg, transparent 16px, transparent 0);
}
.skew-cc-marcas {
	width: 100%;
	height: 100px;
	position: absolute;
	left: 0px;
	margin-top: -100px;
	background: linear-gradient(to right bottom, transparent 49%, #f5f5f5 50%), linear-gradient(-50deg, transparent 16px, transparent 0);
}

.skew-cc-g {
	width: 100%;
	height: 100px;
	position: absolute;
	left: 0px;
	background: linear-gradient(to right bottom, $pri 49%, transparent 50%), linear-gradient(-50deg, transparent 16px, transparent 0);
}

.skew-c {
	margin-top: -100px;
	width: 100%;
	height: 100px;
	position: absolute;
	left: 0px;
	background: linear-gradient(to left bottom, transparent 49%, rgba(236, 236, 236, 1) 50%);
}

.skew-green {
	width: 100%;
	height: 100px;
	position: absolute;
	left: 0px;
	background: linear-gradient(to left bottom, transparent 49%, #009090 50%);
}

div.formacionEmpresas > span {
    letter-spacing: 0.1rem;
}

.skew-grey {
	width: 100%;
	height: 100px;
	position: absolute;
	left: 0px;
	margin-top: -100px;
	background: linear-gradient(to left bottom, transparent 49%, #4e4e4e 50%);
}

.skew-btm {
	width: 100%;
	height: 100px;
	position: absolute;
	left: 0px;
	background: linear-gradient(to left bottom, #4e4e4e 49%, transparent 50%);
  @media screen and (max-width: 770px) {
    display: none !important;
  }
}

.skew-btm-whitesmoke {
	width: 100%;
	height: 100px;
	position: absolute;
	left: 0;
	background: linear-gradient(to left bottom, whitesmoke 49%, transparent 50%);

  @media screen and (max-width: 770px) {
      display: none !important;
  }
}

.skew-btm-green {
	margin-top: -100px;
	width: 100%;
	height: 100px;
	position: absolute;
	left: 0px;
	background: linear-gradient(to left bottom, #009090 49%, transparent 50%);
}
.info-item svg {
	margin-left: 10px;
	font-size: 20px;
}
.title {
	z-index: 2;
	bottom: 2rem;
	position: absolute;
	width: 100%;
	text-align: center;
}

.content {
	position: absolute;
	opacity: 0;
}

.info {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}

.item {
	z-index: 0;
	cursor: pointer;
}

.title h2 {
	color: #fff;
	font-size: 2.5rem;
	letter-spacing: 6px;
	font-family: 'Kelson';
	border-bottom: 1px solid #fff;
	display: inline-block;
}

.title h3 {
	color: #fff;
	font-family: 'Kelson';
	letter-spacing: 2px;
	font-size: 1.4rem;
}
@media (max-width: 425px) {
	.info {
		opacity: 1;
	}
	.info:after {
		opacity: 0.3;
	}
}
@media (min-width: 1366px) {
	.masteres-card-wrapper {
		padding: 4rem 10rem;
		background-color: #fff;
	}
}
</style>
