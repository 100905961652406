<template>
  <b-row
    data-aos-delay="500"
    data-aos="zoom-in"
    class="container pt-5 pb-5"
  >
    <b-col class="items border1">
      <div
        data-aos="fade-right"
        data-aos-delay="1500"
        class="items-head"
      >
        <p>{{ $t('academics.Dirección') }}</p>
        <hr>
      </div>

      <div class="items-body">
        <div class="items-body-content">
          <a
            href="/cursos-online/business-management"
          ><font-awesome-icon icon="business-time" />
            {{ $t('academics.sub-academics.sa1') }}</a>
          <font-awesome-icon icon="angle-right" />
        </div>
        <div class="items-body-content">
          <a
            href="/cursos-online/comercial-y-ventas"
          ><font-awesome-icon icon="search-dollar" />
            {{ $t('academics.sub-academics.sa2') }}</a>
          <font-awesome-icon icon="angle-right" />
        </div>
        <div class="items-body-content">
          <a
            href="/cursos-online/administracion-publica-y-gestion"
          ><font-awesome-icon icon="chart-bar" />
            {{ $t('academics.sub-academics.sa3') }}</a>
          <font-awesome-icon icon="angle-right" />
        </div>
      </div>
    </b-col>
    <b-col class="items">
      <div
        data-aos="fade-right"
        data-aos-delay="1500"
        class="items-head"
      >
        <p>{{ $t('academics.Digital') }}</p>
        <hr>
      </div>

      <div class="items-body">
        <div class="items-body-content">
          <a
            href="/cursos-online/nuevas-tecnologias"
          ><font-awesome-icon icon="shopping-cart" />
            {{ $t('academics.sub-academics.sa4') }}</a>
          <font-awesome-icon icon="angle-right" />
        </div>
        <div class="items-body-content">
          <a
            href="/cursos-online/programacion-y-bases-de-datos"
          ><font-awesome-icon icon="comment-alt" />
            {{ $t('academics.sub-academics.sa5') }}</a>
          <font-awesome-icon icon="angle-right" />
        </div>
        <div class="items-body-content">
          <a
            href="/cursos-online/sistemas-operativos"
          ><font-awesome-icon icon="user-shield" />
            {{ $t('academics.sub-academics.sa6') }}</a>
          <font-awesome-icon icon="angle-right" />
        </div>
      </div>
    </b-col>
    <b-col class="items border2">
      <div
        data-aos="fade-right"
        data-aos-delay="1500"
        class="items-head"
      >
        <p>{{ $t('academics.Recursos') }}</p>
        <hr>
      </div>

      <div class="items-body">
        <div class="items-body-content">
          <a
            href="/cursos-online/recursos-humanos"
          ><font-awesome-icon icon="comments-dollar" />
            {{ $t('academics.sub-academics.sa7') }}</a>
          <font-awesome-icon icon="angle-right" />
        </div>
        <div class="items-body-content">
          <a
            href="/cursos-online/habilidades-directivas"
          ><font-awesome-icon icon="clipboard-check" />
            {{ $t('academics.sub-academics.sa8') }}</a>
          <font-awesome-icon icon="angle-right" />
        </div>
        <div class="items-body-content">
          <a
            href="/cursos-online/prevencion-de-riesgos-laborales"
          ><font-awesome-icon icon="landmark" />
            {{ $t('academics.sub-academics.sa9') }}</a>
          <font-awesome-icon icon="angle-right" />
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
    name: 'HomeMenu'
};
</script>

<style scoped lang="scss">
$pri: transparent;
$sec: rgba(222, 222, 222, 0.4);
$dark-pri: #7dd2d9;
* {
    font-family: 'Rubik', sans-serif;
}

a {
    color: #686868;
    &:link {
        text-decoration: none;
    }
    &:hover {
        color: black;
    }
}

.container {
    margin: auto;
    margin-bottom: 100px;
    position: relative;
    background-color: $pri;
}

.items {
    width: auto;
    background: $sec;
    border-top: 10px solid #dddddd;
    border-color: #0070a8;
}

.items-head p {
    padding: 5px 20px;
    margin: 10px;
    color: #808080;
    font-weight: bold;
    font-size: 20px;
}

.items-head hr {
    width: 20%;
    margin: 0px 30px;
    border: 1px solid #dddddd;
}

.items-body {
    padding: 10px;
    margin: 10px;
    display: grid;
    grid-gap: 10px;
}

.items-body-content {
    padding: 10px;
    display: grid;
    grid-template-columns: 10fr 1fr;
    font-size: 13px;
    grid-gap: 10px;
    border: 1px solid transparent;
    cursor: pointer;
    color: #686868;
}

.items-body-content:hover {
    border: 1px solid #009090;
}

.items-body-content i {
    align-self: center;
    font-size: 15px;
    color: $dark-pri;
    font-weight: bold;
    animation: icon 1.5s infinite forwards;
}

@keyframes icon {
    0%,
    100% {
        transform: translate(0px);
    }
    50% {
        transform: translate(3px);
    }
}
</style>
