<template>
  <div
    class="wrapper"
    style="padding-bottom: 100px"
  >
    <b-container>
      <nav
        id="navigation"
        class="site-navigation"
      >
        <ul id="">
          <li>
            <a>{{ $t('message.the_club') }}
              <font-awesome-icon icon="angle-down" />
            </a>
            <ul class="dropdown">
              <li class="menu-item sub-menu">
                <a
                  href="#!"
                  target="_blank"
                >{{ $t('encuerto.Comité_ejecutivo') }}</a>
              </li>
              <li class="menu-item sub-menu">
                <a
                  href="#!"
                  target="_blank"
                >{{ $t('encuerto.Delegaciones') }}</a>
              </li>
              <li class="menu-item sub-menu">
                <a
                  href="#!"
                  target="_blank"
                >{{ $t('encuerto.Koinonías') }}</a>
              </li>
            </ul>
          </li>
          <li>
            <a>{{ $t('message.services') }}
              <font-awesome-icon icon="angle-down" />
            </a>
            <ul class="dropdown">
              <li>
                <a
                  href="#!"
                  target="_blank"
                >{{ $t('encuerto.Mentoría') }}</a>
              </li>
              <li>
                <a
                  href="#!"
                  target="_blank"
                >{{ $t('encuerto.Multiportal') }}</a>
              </li>
              <li>
                <router-link to="/alma-digital/">
                  {{ $t('encuerto.Transformación') }}
                </router-link>
              </li>
              <li>
                <router-link to="/alma-digital/">
                  {{ $t('encuerto.Designing') }}
                </router-link>
              </li>
              <li>
                <a
                  href="/elearning-shop/"
                  style="text-decoration: none;"
                >{{ $t('encuerto.Factoría') }}</a>
              </li>
              <li>
                <a
                  href="/formacion-bonificada/"
                  style="text-decoration: none;"
                >{{ $t('encuerto.Formación') }}</a>
              </li>
            </ul>
          </li>
          <li>
            <a>{{ $t('message.events') }}
              <font-awesome-icon icon="angle-down" />
            </a>
            <ul class="dropdown">
              <li>
                <a href="/coruna/">{{ $t('footer.Coruna') }}</a>
              </li>
              <li>
                <a href="/alicante/">{{
                  $t('footer.Alicante')
                }}</a>
              </li>
              <li>
                <a href="/barcelona-centro/">{{
                  $t('footer.Barcelona')
                }}</a>
              </li>
              <li>
                <a href="/bilbao/">{{ $t('footer.Bilbao') }}</a>
              </li>
              <li>
                <a href="/castellon/">{{
                  $t('footer.Castellon')
                }}</a>
              </li>
              <li>
                <a href="/cordoba/">{{
                  $t('footer.Cordoba')
                }}</a>
              </li>
              <li>
                <a href="/cuenca/">{{ $t('footer.Cuenca') }}</a>
              </li>
              <li>
                <a href="/granada/">{{
                  $t('footer.Granada')
                }}</a>
              </li>
              <li>
                <a href="/larioja/">{{
                  $t('footer.Larioja')
                }}</a>
              </li>
              <li>
                <a href="/lugones/">{{
                  $t('footer.Lugones_Asturias')
                }}</a>
              </li>
              <li>
                <a href="/juan-bravo/">{{
                  $t('footer.Juan_Bravo')
                }}</a>
              </li>
              <li>
                <a href="/leganes/">{{
                  $t('footer.Madrid_Leganes')
                }}</a>
              </li>
              <li>
                <a href="/malaga/">{{ $t('footer.Malaga') }}</a>
              </li>
              <li>
                <a href="/rioja-navarra/">Navarra</a>
              </li>
              <li>
                <a href="/tenerife/">{{
                  $t('footer.Tenerife')
                }}</a>
              </li>
              <li>
                <a href="/vigo/">{{ $t('footer.Galicia') }}</a>
              </li>
            </ul>
          </li>
          <li>
            <a>{{ $t('message.events2') }}
              <font-awesome-icon icon="angle-down" />
            </a>
            <ul class="dropdown">
              <li>
                <a href="/dusseldorf/">{{
                  $t('footer.Dusseldorf')
                }}</a>
              </li>
              <li>
                <a
                  href="https://www.aicad.gt/"
                  target="_blank"
                >{{ $t('footer.GUATEMALA') }}</a>
              </li>
              <li>
                <a
                  href="https://www.aicad.com.mx/"
                  target="_blank"
                >{{ $t('footer.Mexico') }}</a>
              </li>
              <li>
                <a
                    href="https://www.aicad.cl/"
                    target="_blank"
                >{{ $t('footer.Chile') }}</a>
              </li>
              <li>
                <a
                    href="https://www.aicad.do/"
                    target="_blank"
                >{{ $t('footer.Dominicana') }}</a>
              </li>
            </ul>
          </li>
          <li>
            <a>{{ $t('message.partners') }}
              <font-awesome-icon icon="angle-down" />
            </a>
            <ul class="dropdown">
              <li><a href="/practicas-en-empresas/">{{ $t('encuerto.Prácticas') }}</a></li>
              <li><a href="/practicas-alumnos/">{{ $t('encuerto.Alumnos') }}</a></li>
              <li><a href="/convenio-de-practicas/">{{ $t('encuerto.Convenio') }}</a></li>
            </ul>
          </li>
        </ul>
      </nav>
    </b-container>
    <b-container>
      <div class="contenidoFiltros">
        <a
          href="https://clubejecutivo.websincoste.com/"
          target="_blank"
        >
          <button class="send-btn">
            <div class="h8">{{ $t('encuerto.Visit') }}</div>
          </button>
        </a>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
    name: 'HomeEncuerto'
};
</script>

<style scoped lang="scss">
.wrapper {
    text-align: center;
}

li {
    color: black !important;
}

.subtitles {
    line-height: 15pt;
    font-size: 15px;
    padding-left: 40px;
}

.title-selection:hover {
    color: #cccccc;
}

.site-navigation {
    letter-spacing: -0.4px;
    display: block;
    font-family: 'LatoWebThin', sans-serif;
    font-size: 12px;
    font-weight: bold;
}

.site-navigation ul {
    background: white;
    list-style: none;
    padding-left: 0;
    margin: 0 auto;
}

.site-navigation li {
    color: #fff;
    background: whitesmoke;
    display: block;
    padding: 8px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
}

@media only screen and (max-width: 950px) {
    .site-navigation li {
        float: none !important;
    }
}

.site-navigation li a {
    color: black;
    text-decoration: none;
    display: block;
}

.site-navigation li:hover {
    background: white;
    cursor: pointer;
}

.site-navigation ul li ul {
    visibility: hidden;
    width: 45%;
    position: absolute;
    transition: visibility 0.1s ease-in;
    right: 0;
    z-index: 999;
    border: 2px solid #bbbbbb;
    border-radius: 50px;
    border-right: none;
    border-left: none;
}

.site-navigation ul li:hover > ul,
.site-navigation ul li ul:hover {
    visibility: visible;
}

.site-navigation ul li ul li {
    clear: both;
    padding: 5px 0 0px 0px;
    width: 100%;
}

.site-navigation ul li ul li:hover {
    background: white;
}

.send-btn {
    width: 100%;
    height: 50px;
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 52%,
        rgba(0, 144, 144, 1) 100%
    );
    position: relative;
    border: #0f6674 solid;
    transition: 0.3s ease all;
    @media only screen and (max-width: 774px) {
        top: 0;
        width: 95%;
    }

    &:hover {
        background: url(../assets/img/icons/icon-send.png),
            linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 15%,
                rgba(0, 144, 144, 1) 100%
            );
        background-repeat: no-repeat;
        background-position: 90% center;
    }

    .h8 {
        color: #009090;
        font-family: 'LatoWebBlack', sans-serif;
    }
}

.contenidoFiltros {
    padding: 25px 0;
    text-align: center;
}
</style>
