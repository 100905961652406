<template>
  <b-container>
    <b-row>
      <div class="col-xs-12 back-gran">
        <div class="container">
          <div class="col-md-12 openPrograms">
            <div class="formacionEmpresas">
              <span>{{ $t('message.exclusive_partners') }}</span>
            </div>
            <b-row>
              <b-col
                lg="4"
                md="6"
              >
                <img
                  loading="lazy"
                  alt="Software"
                  src="../assets/img/icons/Software.png"
                  class="img-responsive"
                >
                <p class="title_desPrograms">
                  SOFTWARE
                </p>
                <p class="descPrograms">
                  {{ $t('message.software_content') }}
                </p>
              </b-col>
              <b-col
                lg="4"
                md="6"
              >
                <img
                  loading="lazy"
                  alt="Encuentra-Clientes"
                  src="../assets/img/icons/Encuentra-Clientes.png"
                  class="img-responsive"
                >
                <p class="title_desPrograms">
                  {{ $t('message.find_customers') }}
                </p>
                <p
                  class="descPrograms"
                  style="white-space: pre-wrap"
                >
                  {{ $t('message.find_customers_content') }}
                </p>
              </b-col>
              <b-col
                lg="4"
                md="6"
              >
                <img
                  loading="lazy"
                  alt="Soluciones-para-socios"
                  src="../assets/img/icons/Soluciones-para-socios.png"
                  class="img-responsive"
                >
                <p class="title_desPrograms">
                  {{ $t('message.solutions_for_partners') }}
                </p>
                <p
                  class="descPrograms"
                  style="white-space: pre-wrap"
                >
                  {{
                    $t(
                      'message.solutions_for_partners_content'
                    )
                  }}
                </p>
              </b-col>
              <b-col
                lg="6"
                md="6"
              >
                <img
                  loading="lazy"
                  alt="Tecnologias"
                  src="../assets/img/icons/Tecnologias.png"
                  class="img-responsive"
                >
                <p class="title_desPrograms">
                  {{ $t('message.technologies') }}
                </p>
                <p
                  class="descPrograms"
                  style="white-space: pre-wrap"
                >
                  {{ $t('message.technologies_content') }}
                </p>
              </b-col>
              <b-col
                lg="6"
                md="12"
              >
                <img
                  loading="lazy"
                  alt="Escuela-de-negocios"
                  src="../assets/img/icons/Escuela-de-negocios.png"
                  class="img-responsive"
                >
                <p class="title_desPrograms">
                  {{ $t('message.business_school') }}
                </p>
                <p
                  class="descPrograms"
                  style="white-space: pre-wrap"
                >
                  {{ $t('message.business_school_content') }}
                </p>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-row>
  </b-container>
</template>

<script>
export default {
    name: 'HomeSoluciones'
};
</script>

<style scoped lang="scss">
.back-gran {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    padding-top: 2%;
    padding-bottom: 10%;
    transform: skewY(2deg);
    -webkit-transform: skewY(2deg);
}

.col-xs-12 {
    margin: 0 auto;
}

.openPrograms {
    text-align: center;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    margin: 0 auto;
    transform: skewY(-2deg);
    -webkit-transform: skewY(-2deg);
}

.openPrograms .text-title-open-programs {
    font-size: 34px;
    color: #ffffff;
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'open sans condensed', Arial, sans-serif;
    margin-right: 15px;
}

.title_desPrograms {
    font-family: 'Open sans', Arial, sans-serif;
    font-weight: 700 !important;
    font-size: 16px !important;
    text-transform: uppercase !important;
    margin: 0px !important;
    padding: 0px !important;
    padding-bottom: 5px !important;
    color: #ffffff;
}

.openPrograms .title_desPrograms ~ .descPrograms {
    color: #ffffff;
    font-family: 'Open sans', Arial, sans-serif;
}

.openPrograms .descPrograms,
.customPrograms .descPrograms {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    width: 100%;
    float: left;
    margin: 0px 0;
    padding: 0;
    text-transform: none;
}

.openPrograms .text-title-open-programs span {
    font-weight: 400;
    line-height: 13px;
    margin-bottom: 24px;
}

.back-gran.grey {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    background: #4e4e4e;
    top: -3px;
}

.container {
    height: auto;
}

.formacionEmpresas {
    text-align: center;
    font-size: 34px;
    color: #ffffff;
    font-family: 'open sans condensed', Arial, sans-serif;
    letter-spacing: -2px;
    margin-top: -20px;
    margin-bottom: 40px;
}

.formacionEmpresas span {
    font-weight: 400;
    line-height: 13px;
    margin-bottom: 24px;
}

@media screen and (max-width: 767px) {
    .twentypercent {
        width: 100%;
    }

    .textNoticias {
        min-height: unset !important;
    }

    .contenidoFiltros {
        padding: 0px;
    }
}

@media (max-width: 503px) {
    .formacionEmpresas {
        font-size: 19px;
    }
}

@media (min-width: 768px) {
    .openPrograms .col-md-2 {
        -ms-flex: 0 0 20% !important;
        flex: 0 0 20% !important;
        max-width: 20% !important;
    }
}
</style>
