<template>
  <div
    id="topHead"
    class="header"
    style="z-index: 5 !important;"
  >
    <div class="blue-header">
      <div class="container hidden-sm-down">
        <div class="row">
          <div class="col-12 col-sm-8 menu-header">
            <ul class="inicio">
              <li>
                <a
                  :class="[language == 'es' ? 'activeLang' : '']"
                  name="es"
                  class="langText"
                  @click="changeLanguage('es')"
                >ES</a>
              </li>
              <li>
                <a
                  :class="[language == 'en' ? 'activeLang' : '']"
                  name="en"
                  class="langText"
                  @click="changeLanguage('en')"
                >EN</a>
              </li>
              <li>
                <router-link to="/inversores/">
                  {{ $t('green_header.Inversores') }}
                </router-link>
              </li>
              <li>
                <a
                  href="/fundacion-aicad/"
                  target="_blank"
                >{{ $t('green_header.Fundacion') }}</a>
              </li>
              <li>
                <a
                  href="/fintech/"
                  target="_blank"
                >{{ $t('green_header.Fintech') }}</a>
              </li>
              <li>
                <a
                  href="https://clubejecutivo.websincoste.com/spain/"
                  target="_blank"
                >{{ $t('green_header.Club') }}</a>
              </li>
              <li>
                <a
                  href="/emprendedores/"
                  target="_blank"
                >Emprendedores</a>
              </li>
              <li>
                <router-link to="/masteres-posgrados/">
                  {{ $t('green_header.cursosconpracticas') }}
                </router-link>
              </li>
              <li>
                <a
                  target="_blank"
                  href="/news/"
                >{{ $t('green_header.Blog') }}</a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="/area-de-afiliado/"
                >{{ $t('green_header.Área') }}</a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="/fundacion-aicad/prensa"
                >Prensa</a>
              </li>
            </ul>
          </div>
          <div class="col-12 col-sm-4 menu-header">
            <div class="contacto">
              <div class="cont">
                <a
                  href="/login/"
                  target="_blank"
                >
                  <img
                    loading="lazy"
                    style="width: 20px;margin-right: 5px;"
                    src="../assets/icon/icon_lock.svg"
                    :alt="$t('green_header.Cuenta')"
                  >
                </a>
                <img
                  loading="lazy"
                  src="../assets/img/icons/icono-telefono3.jpg"
                  alt="Contacto"
                >
                <a
                  href="tel:+34928354668"
                  rel="nofollow"
                >
                  <span style="color: white;">{{ $t('green_header.tel') }}</span>
                </a>
                <img
                  loading="lazy"
                  src="../assets/img/whatsapp-icon-white.png"
                  alt="Contacto"
                  style="margin-left:5px;width:20px;"
                >
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send?phone=+34617783460"
                  rel="nofollow"
                >
                  <span style="color: white;">+34 617 78 34 60</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="path-burger">
        <div class="animate-path">
          <div class="path-rotation" />
        </div>
      </div>
    </div>
    <VueFixedHeader
      :threshold="propsData.threshold"
      :header-class="propsData.headerClass"
      :fixed-class="propsData.fixedClass"
      :hide-scroll-up="propsData.hideScrollUp"
      @change="updateFixedStatus"
    >
      <div
        id="nav_principal"
        class="nuevo-menus"
      >
        <div
          class="menu"
          style="text-align: center;vertical-align: middle;"
        >
          <div
            class="row margin-row-right container"
            style="margin: auto;"
          >
            <div class="col-12 col-sm-2 col-md-2 col-xl-3 menuLogo">
              <div
                itemscope
                itemtype="http://schema.org/Organization"
                class="logoAicad"
              >
                <router-link
                  :to="{ name: 'home' }"
                  style="cursor:pointer; border: unset"
                >
                  <img
                    loading="lazy"
                    src="../assets/img/AICAD-LOGO.png"
                    alt="Aicad Business School"
                    class="logo aicad"
                    itemprop="logo"
                    tab="Aicad Business School"
                  >
                </router-link>
              </div>
            </div>
            <div
              class="menuPrinc"
              :class="[isClosed ? 'hiddenMenu' : 'showedMenu']"
            >
              <nav
                id="navigation"
                class="site-navigation"
                role="navigation"
              >
                <ul
                  id="menu"
                  class="sous-menu"
                >
                  <li
                    class="menu-item"
                    @click="show = !show"
                  >
                    <a href="#">
                      {{ $t('header.institución') }}
                      <font-awesome-icon icon="angle-down" />
                    </a>
                    <div
                      v-if="show"
                      class="dropdown-institution dr"
                      style
                    >
                      <b-container style="max-width: 1000px;">
                        <b-row style>
                          <b-col
                            sm="12"
                            md="12"
                            xl="4"
                            style="background-color: white; padding-top: 20px;"
                          >
                            <div>
                              <router-link
                                to="/conoce-aicad"
                                style="color: black !important;"
                              >
                                <h2 class="title-selection">
                                  <img
                                    loading="lazy"
                                    src="../assets/img/Header/Conoce-Aicad.png"
                                    alt="conoce-aicad"
                                    class="img-title"
                                  >
                                </h2>
                              </router-link>
                            </div>
                            <div class="subtitles">
                              <router-link to="/conoce-aicad/mision">
                                <div>
                                  {{ $t('menu.subtitles.misión') }}
                                </div>
                              </router-link>
                              <router-link to="/conoce-aicad/vision">
                                <div>
                                  {{ $t('menu.subtitles.visión') }}
                                </div>
                              </router-link>
                              <router-link to="/conoce-aicad/valores">
                                <div>
                                  {{ $t('menu.subtitles.valores') }}
                                </div>
                              </router-link>
                              <router-link to="/conoce-aicad/cultura">
                                <div>
                                  {{ $t('menu.subtitles.cultura') }}
                                </div>
                              </router-link>
                              <router-link to="/conoce-aicad/filosofia">
                                <div>
                                  {{ $t('menu.subtitles.filosofia') }}
                                </div>
                              </router-link>
                              <hr>
                              <div>
                                <router-link to="/areas-de-actividad/">
                                  <div>
                                    <font-awesome-icon icon="caret-right" />
                                    {{ $t('menu.subtitles.15_areas_de_actividad') }}
                                  </div>
                                </router-link>
                              </div>
                              <router-link to="/ecosistema-de-innovacion/">
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.titles.ecosistema_de_innovacion') }}
                                </div>
                              </router-link>
                              <div>
                                {{ $t('menu.subtitles.executive_education') }}
                                :
                              </div>
                              <router-link
                                :to="{
                                  name: 'education'
                                }"
                              >
                                <div style="list-style-type: none;">
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.quieres_seducir') }}
                                </div>
                              </router-link>
                              <router-link
                                :to="{
                                  name: 'education2'
                                }"
                              >
                                <div style="list-style-type: none;">
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.suenas_con_captar') }}
                                </div>
                              </router-link>
                            </div>
                            <br>
                          </b-col>
                          <b-col
                            sm="12"
                            md="6"
                            xl="4"
                            style="background-color: white; padding-top: 20px;"
                          >
                            <div>
                              <router-link to="/alma-digital/">
                                <h2 class="title-selection">
                                  <img
                                    loading="lazy"
                                    src="../assets/img/Header/Alma-Digital.png"
                                    alt="alma-digital"
                                    class="img-title2"
                                  >
                                </h2>
                              </router-link>
                            </div>
                            <div class="subtitles">
                              <router-link to="/alma-digital/transformacion-digital">
                                <div style="list-style-type: none;">
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.transformacion_digital') }}
                                </div>
                              </router-link>
                              <router-link to="/alma-digital/digital-sales-journey">
                                <div style="list-style-type: none;">
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.digital_sales_journey') }}
                                </div>
                              </router-link>
                              <router-link to="/alma-digital/digital-strategy-priority">
                                <div style="list-style-type: none;">
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.digital_strategy_priority') }}
                                </div>
                              </router-link>
                              <router-link to="/alma-digital/digital-sales-info">
                                <div style="list-style-type: none;">
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.digital_sales') }}
                                </div>
                              </router-link>
                              <router-link to="/alma-digital/compentencias-digitales">
                                <div style="list-style-type: none;">
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.comptencias_digitales') }}
                                </div>
                              </router-link>
                              <a href="/universidad-corporativa/">
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  Universidad corporativa
                                </div>
                              </a>
                            </div>
                            <hr>
                            <div class="subtitles">
                              <router-link to="/formacion-bonificada">
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.formación_bonificada') }}
                                </div>
                              </router-link>
                              <a href="/responsabilidad-social/">
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.titles.responsabilidad_social') }}
                                </div>
                              </a>
                              <hr>
                            </div>
                          </b-col>
                          <b-col
                            sm="12"
                            md="6"
                            xl="4"
                            style="background-color: white; padding-top: 20px;"
                          >
                            <div>
                              <router-link to="/delegaciones">
                                <h2 class="title-selection">
                                  <img
                                    loading="lazy"
                                    src="../assets/img/Header/Franquicias.png"
                                    alt="delegaciones"
                                    class="img-title3"
                                  >
                                </h2>
                              </router-link>
                            </div>
                            <div class="subtitles">
                              <router-link to="/franquicias-de-exito/">
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.de_exito') }}
                                </div>
                              </router-link>
                              <router-link to="/socio-director-franquiciado/">
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.Director') }}
                                </div>
                              </router-link>
                              <a
                                target="_blank"
                                href="/agente-digital/"
                              >
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.agente') }}
                                </div>
                              </a>
                            </div>
                            <br>
                          </b-col>
                        </b-row>
                      </b-container>
                    </div>
                  </li>
                  <li
                    class="menu-item"
                    @click="showPracticas = !showPracticas"
                  >
                    <a href="#">
                      {{ $t('header.practicas') }}
                      <font-awesome-icon icon="angle-down" />
                    </a>
                    <div
                      v-if="showPracticas"
                      class="dropdown-practicas dr"
                    >
                      <b-container style="max-width: 800px;">
                        <b-row style>
                          <b-col
                            sm="12"
                            md="6"
                            xl="6"
                            style="background-color: white; padding-top: 20px; padding-bottom: 20px;"
                          >
                            <div>
                              <a
                                target="_blank"
                                href="/practicas/"
                              >
                                <h2 class="title-selection">
                                  <img
                                    loading="lazy"
                                    src="../assets/img/Header/Empresa.png"
                                    alt="practicas-en-empresa"
                                    class="img-title"
                                  >
                                </h2>
                              </a>
                            </div>
                            <div class="subtitles">
                              <router-link to="/practicas-en-empresas/">
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.prácticas_en_empresas') }}
                                </div>
                              </router-link>
                            </div>
                            <div class="subtitles">
                              <a
                                target="_blank"
                                href="/practicas/empresas-vip"
                              >
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.prácticas_en_empresas_vip') }}
                                </div>
                              </a>
                            </div>
                            <div class="subtitles">
                              <a
                                target="_blank"
                                href="/practicas/contratar-becarios"
                              >
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.contratar_becarios_en_prácticas') }}
                                </div>
                              </a>
                            </div>
                            <div class="subtitles">
                              <a
                                target="_blank"
                                href="/practicas/agencia-practicas"
                              >
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.agencia_de_prácticas_en_empresas') }}
                                </div>
                              </a>
                            </div>
                            <div class="subtitles">
                              <a
                                target="_blank"
                                href="/practicas/empresas-erasmus"
                              >
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.prácticas_en_empresas_erasmus') }}
                                </div>
                              </a>
                            </div>
                            <div class="subtitles">
                              <a
                                target="_blank"
                                href="/practicas/contratar-becarios-extranjeros"
                              >
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.contratar_becarios_extranjeros') }}
                                </div>
                              </a>
                            </div>
                            <div class="subtitles">
                              <a
                                target="_blank"
                                href="/practicas/paises-practicas"
                              >
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.paises_para_hacer_prácticas_erasmus') }}
                                </div>
                              </a>
                            </div>
                            <div class="subtitles">
                              <a
                                target="_blank"
                                href="/practicas/contratar-becarios-aicad/"
                              >
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.como_contratar_becarios_en_aicad') }}
                                </div>
                              </a>
                            </div>
                            <div class="subtitles">
                              <a
                                target="_blank"
                                href="/practicas/universidades-colaboradoras/"
                              >
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.universidades_colaboradoras') }}
                                </div>
                              </a>
                            </div>
                            <div class="subtitles">
                              <a
                                target="_blank"
                                href="/practicas/faq-empresas"
                              >
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.preguntas_frecuentes_de_empresas') }}
                                </div>
                              </a>
                            </div>
                            <div class="subtitles">
                              <a
                                target="_blank"
                                href="/practicas/faq-alumnos"
                              >
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.preguntas_frecuentes_de_alumnos') }}
                                </div>
                              </a>
                            </div>
                          </b-col>
                          <b-col
                            sm="12"
                            md="6"
                            xl="6"
                            style="background-color: white; padding-top: 20px; padding-bottom: 20px;"
                          >
                            <div>
                              <router-link to="/practicas-alumnos/">
                                <h2 class="title-selection">
                                  <img
                                    loading="lazy"
                                    src="../assets/img/Header/Alumno.png"
                                    alt="practicas-alumnos"
                                    class="img-title"
                                  >
                                </h2>
                              </router-link>
                            </div>
                            <div class="subtitles">
                              <a href="#">
                                <router-link to="/convenio-de-practicas/">
                                  <div>
                                    <font-awesome-icon icon="caret-right" />
                                    {{ $t('menu.subtitles.convenio_de_practicas') }}
                                  </div>
                                </router-link>
                              </a>
                              <a
                                target="_blank"
                                href="/masteres-posgrados/"
                              >
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.masters_con_practicas') }}
                                </div>
                              </a>
                              <a href="#">
                                <router-link
                                  :to="{
                                    name: 'MasterTalento'
                                  }"
                                >
                                  <div>
                                    <font-awesome-icon icon="caret-right" />
                                    {{ $t('menu.subtitles.masters_talento') }}
                                  </div>
                                </router-link>
                              </a>
                              <div>
                                {{ $t('menu.subtitles.practicas_en_madrid') }}
                              </div>
                              <div>
                                {{ $t('menu.subtitles.practicas_en_barcelona') }}
                              </div>
                              <div>
                                {{ $t('menu.subtitles.practicas_en_espana') }}
                              </div>
                              <div>
                                {{ $t('menu.subtitles.practicas_en_el_mundo') }}
                              </div>
                              <a
                                href="https://planetajob.es/"
                                target="_blank"
                                rel="nofollow"
                              >
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.oferta_de_practicas') }}
                                </div>
                              </a>
                            </div>
                          </b-col>
                        </b-row>
                      </b-container>
                    </div>
                  </li>
                  <li
                    class="menu-item"
                    @click="showSocios = !showSocios"
                  >
                    <a href="#">
                      {{ $t('header.servicios_socios') }}
                      <font-awesome-icon icon="angle-down" />
                    </a>
                    <div
                      v-if="showSocios"
                      class="dropdown-socios dr"
                      style
                    >
                      <b-container style="max-width: 1050px;">
                        <b-row>
                          <b-col
                            sm="12"
                            md="12"
                            xl="4"
                            style="background-color: white; padding-top: 20px;"
                          >
                            <div>
                              <router-link to="/consultoria-empresarial">
                                <h2 class="title-selection">
                                  <img
                                    loading="lazy"
                                    src="../assets/img/Header/Consultoria-empresarial.png"
                                    alt="consultoría-empresarial"
                                    class="img-title"
                                  >
                                </h2>
                              </router-link>
                            </div>
                            <div class="subtitles">
                              <a>
                                <div>
                                  {{ $t('menu.subtitles.business_intelligence') }}
                                </div>
                              </a>
                              <a>
                                <div>
                                  {{ $t('menu.subtitles.analisis_de_competencias') }}
                                </div>
                              </a>
                              <a>
                                <div>
                                  {{ $t('menu.subtitles.e-skills') }}
                                </div>
                              </a>
                              <a>
                                <div>
                                  {{ $t('menu.subtitles.aicad_training_pro') }}
                                </div>
                              </a>
                              <a>
                                <div>
                                  {{ $t('menu.subtitles.inteligencia_artificial') }}
                                </div>
                              </a>
                              <a>
                                <div>
                                  {{ $t('menu.subtitles.fintech') }}
                                </div>
                              </a>
                              <a>
                                <div>
                                  {{ $t('menu.subtitles.software_web') }}
                                </div>
                              </a>
                              <a>
                                <div>
                                  {{ $t('menu.subtitles.e-comunidades') }}
                                </div>
                              </a>
                              <a>
                                <div>
                                  {{ $t('menu.subtitles.webinars') }}
                                </div>
                              </a>
                              <a>
                                <div>
                                  {{ $t('menu.subtitles.encubadora') }}
                                </div>
                              </a>
                              <a>
                                <div>
                                  {{ $t('menu.subtitles.social_selling') }}
                                </div>
                              </a>
                              <a>
                                <div>
                                  {{ $t('menu.subtitles.prestigio_digital') }}
                                </div>
                              </a>
                              <a
                                target="_blank"
                                href="/blanqueo-capitales/"
                              >
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  Blanqueo Capitales
                                </div>
                              </a>
                            </div>
                            <br>
                          </b-col>
                          <b-col
                            sm="12"
                            md="12"
                            xl="4"
                            style="background-color: white; padding-top: 20px;"
                          >
                            <div>
                              <h2 class="title-selection">
                                <img
                                  loading="lazy"
                                  src="../assets/img/Header/Reclutamiento-seleccion.png"
                                  alt="seleccion-y-reclutamiento-de-personal"
                                  class="img-title"
                                >
                              </h2>
                            </div>
                            <div class="subtitles">
                              <a
                                target="_blank"
                                href="/headhunting/en/"
                              >
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.headhunting_internacional') }}
                                </div>
                              </a>
                              <a
                                href="https://planetajob.es/"
                                target="_blank"
                                rel="nofollow"
                              >
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.ofertas_de_empleo') }}
                                </div>
                              </a>
                              <a
                                target="_blank"
                                href="/seleccion-y-reclutamiento-de-personal/"
                              >
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.selección_de_personal') }}
                                </div>
                              </a>
                            </div>
                            <hr>
                            <div>
                              <h2 class="title-selection">
                                <img
                                  loading="lazy"
                                  src="../assets/img/Header/Clubes-de-medida.png"
                                  alt="clubes-de-medida"
                                  class="img-title2"
                                >
                              </h2>
                            </div>
                            <div class="subtitles">
                              <a
                                href="https://clubejecutivo.websincoste.com/"
                                target="_blank"
                              >
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.club_ejecutivo') }}
                                </div>
                              </a>
                              <a
                                href="/emprendedores/"
                                target="_blank"
                              >
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.club_del_emprendedor') }}
                                </div>
                              </a>
                              <a
                                target="_blank"
                                href="/profesorado-e-investigacion/"
                              >
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.profesorado_union') }}
                                </div>
                              </a>
                            </div>
                            <br>
                          </b-col>
                          <b-col
                            sm="12"
                            md="12"
                            xl="4"
                            style="background-color: white; padding-top: 20px;"
                          >
                            <div>
                              <h2 class="title-selection">
                                <img
                                  loading="lazy"
                                  src="../assets/img/Header/Certificaciones.png"
                                  alt="certificaciones"
                                  class="img-title3"
                                >
                              </h2>
                            </div>
                            <div class="subtitles">
                              <router-link
                                :to="{
                                  name: 'AgenteTuristico'
                                }"
                              >
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.agente_turístico') }}
                                </div>
                              </router-link>
                              <router-link
                                :to="{
                                  name: 'ProfesionalConfienza'
                                }"
                              >
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.profesional_de_confianza') }}
                                </div>
                              </router-link>
                              <router-link
                                :to="{
                                  name: 'FamiliaSegura'
                                }"
                              >
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.familia_segura') }}
                                </div>
                              </router-link>
                              <a
                                href="/subvenciones/"
                                target="_blank"
                              >
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.titles.subvenciones') }}
                                </div>
                              </a>
                            </div>
                            <hr>
                            <div>
                              <h2 class="title-selection">
                                <img
                                  loading="lazy"
                                  src="../assets/img/Header/Marcas-propias.png"
                                  alt="marcas-proprias"
                                  class="img-title2"
                                >
                              </h2>
                            </div>
                            <div class="subtitles">
                              <a
                                href="https://www.agencialopd.com/"
                                target="_blank"
                                rel="nofollow"
                              >
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.agencia_rgpd') }}
                                </div>
                              </a>
                              <a href="#">
                                <router-link
                                  :to="{
                                    name: 'BusinessBreak'
                                  }"
                                >
                                  <div>
                                    <font-awesome-icon icon="caret-right" />
                                    {{ $t('menu.subtitles.business_breaks') }}
                                  </div>
                                </router-link>
                              </a>
                              <a
                                href="https://google-my-business.websincoste.com/"
                                target="_blank"
                              >
                                <div>
                                  <font-awesome-icon icon="caret-right" />
                                  {{ $t('menu.subtitles.aicad_my_business') }}
                                </div>
                              </a>
                              <a href="#">
                                <router-link
                                  :to="{
                                    name: 'Contenido'
                                  }"
                                >
                                  <div>
                                    <font-awesome-icon icon="caret-right" />
                                    {{ $t('menu.subtitles.aicad_digital') }}
                                  </div>
                                </router-link>
                              </a>
                            </div>
                          </b-col>
                        </b-row>
                      </b-container>
                    </div>
                  </li>

                  <li class="menu-item">
                    <a
                      target="_blank"
                      href="/cursos-y-masteres/"
                    >{{ $t('header.e-Learning') }}</a>
                  </li>
                  <li
                    class="menu-item"
                    @click="showIdiomas = !showIdiomas"
                  >
                    <router-link to="/escuela-de-idiomas">
                      {{ $t('header.idiomas') }}
                    </router-link>
                  </li>
                  <li class="menu-item">
                    <a
                      href="https://planetajob.es/company-home"
                      target="_blank"
                      rel="nofollow"
                    >
                      {{ $t('header.aicad_jobs') }}
                      <font-awesome-icon icon="angle-down" />
                    </a>
                    <ul class="dropdown">
                      <li class="menu-item sub-menu">
                        <a
                          href="https://planetajob.es/login"
                          target="_blank"
                          rel="nofollow"
                        >{{ $t('header.empleo.portal_empleo') }}</a>
                      </li>
                      <li class="menu-item sub-menu">
                        <a
                          target="_blank"
                          href="/agente-digital/"
                        >{{ $t('header.empleo.agente_digital') }}</a>
                      </li>
                      <li class="menu-item sub-menu">
                        <a
                          target="_blank"
                          href="/head-hunting/"
                        >{{ $t('AICAD RECRUITMENT') }}</a>
                      </li>
                      <li class="menu-item sub-menu">
                        <a
                          target="_blank"
                          href="/emprendedores/"
                        >{{ $t('AICAD EMPRENDEDORES') }}</a>
                      </li>
                      <li class="menu-item sub-menu">
                        <a
                          target="_blank"
                          href="https://riodigital.xyz"
                        >{{ $t('Rio Digital') }}</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div
          id="hamburger"
          :class="[isClosed ? 'is-closed' : 'is-open']"
          class="hamburglar"
          @click="applyClass()"
        >
          <div class="burger-icon">
            <div class="burger-container">
              <span class="burger-bun-top" />
              <span class="burger-filling" />
              <span class="burger-bun-bot" />
            </div>
          </div>
          <div class="burger-ring">
            <svg class="svg-ring">
              <path
                class="path"
                fill="none"
                stroke="#bbbbbb"
                stroke-miterlimit="2"
                stroke-width="3"
                d="M 34 2 C 16.3 2 2 16.3 2 34 s 14.3 32 32 32 s 32 -14.3 32 -32 S 51.7 2 34 2"
              />
            </svg>
          </div>
          <svg
            width="0"
            height="0"
          >
            <mask id="mask">
              <path
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="#ff0000"
                stroke-miterlimit="10"
                stroke-width="4"
                d="M 34 2 c 11.6 0 21.8 6.2 27.4 15.5 c 2.9 4.8 5 16.5 -9.4 16.5 h -4"
              />
            </mask>
          </svg>
        </div>
      </div>
    </VueFixedHeader>
  </div>
</template>

<script>
import VueFixedHeader from 'vue-fixed-header';

const createData = () => ({
    threshold: 50,
    headerClass: 'vue-fixed-header',
    fixedClass: 'vue-fixed-header--isFixed',
    hideScrollUp: true
});

export default {
    components: {
        VueFixedHeader
    },
    data() {
        return {
            langs: [
                {
                    code: 'en',
                    title: 'English'
                },
                {
                    code: 'es',
                    title: 'Español'
                }
            ],
            isMobile: false,
            locale: '',
            language: '',
            show: 'false',
            showPracticas: 'false',
            showSocios: 'false',
            showIdiomas: 'false',
            fixedStatus: {
                headerIsFixed: false
            },
            propsData: { ...createData() },
            formData: { ...createData() },
            isClosed: true
        };
    },
    mounted() {
        this.language = this.$i18n.locale;
    },
    methods: {
        changeLanguage(lang) {
            this.$i18n.locale = lang;
            this.$router.push({ params: { lang: lang } });
        },
        updateFixedStatus(next) {
            this.fixedStatus.headerIsFixed = next;
        },
        handleClickUpdate() {
            this.propsData = { ...this.formData };
        },
        handleClickCancel() {
            this.formData = { ...this.propsData };
        },
        applyClass() {
            this.isClosed = !this.isClosed;
            if (this.isClosed === true) {
                return 'is-closed';
            } else {
                return 'is-open';
            }
        }
    }
};
</script>

<style scoped lang="scss">
.img-title {
	width: 150px;
	&:hover {
		width: 140px;
		opacity: 0.7;
		background: rgb(0, 0, 0);
		background: radial-gradient(circle, rgba(0, 0, 0, 0) 0%, rgba(0, 144, 144, 0.16290266106442575) 82%, rgba(0, 0, 0, 0) 100%);
		transition: 0.3s ease;
	}
}
.img-title2 {
	width: 100px;
	margin-top: -4px;
	&:hover {
		width: 91px;
		opacity: 0.7;
		background: rgb(0, 0, 0);
		background: radial-gradient(circle, rgba(0, 0, 0, 0) 0%, rgba(0, 144, 144, 0.16290266106442575) 82%, rgba(0, 0, 0, 0) 100%);
		transition: 0.3s ease;
	}
}
.img-title3 {
	width: 180px;
	margin-top: -4px;
	&:hover {
		width: 170px;
		opacity: 0.7;
		background: rgb(0, 0, 0);
		background: radial-gradient(circle, rgba(0, 0, 0, 0) 0%, rgba(0, 144, 144, 0.16290266106442575) 82%, rgba(0, 0, 0, 0) 100%);
		transition: 0.3s ease;
	}
}

@media (max-width: 424px) {
	.subtitles {
		margin-left: -50px !important;
	}
}

@media (max-width: 574px) {
	.logo {
		position: relative;
		z-index: 120;
		left: -40%;
	}
}
.nuevo-menus.vue-fixed-header--isFixed {
	.dr {
		top: 70px;
	}
	.logo {
		position: absolute;
		width: 65px;
		height: 50px;
		top: 20px !important;
		@media (max-width: 768px) {
			z-index: 120;
			left: 0;
		}
	}
	.menu-item {
		background-color: transparent;
	}
	position: fixed;
	top: -20px;
	left: 0;
	z-index: 110;
	width: 100vw;
	background-color: rgba(255, 255, 255, 0.9);
	animation: fadeIn 0.3s ease-out forwards;
	-webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.75);
	box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.75);
	@media (max-width: 424px) {
		background-color: transparent;
		-webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0);
		-moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0);
		box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0);
		position: relative;
		.subtitles {
			margin-left: -50px;
		}
	}
	@media (max-width: 950px) {
		top: -10px;
		height: 100px;
		position: relative;
		.hamburglar {
			top: 25px !important;
		}
		.dr {
			top: 20px;
		}
		.menuPrinc {
			top: 100px !important;
		}
	}
}
.menu-header.vue-fixed-header--isFixed {
	a {
		color: #000;
	}

	top: 10px;
	right: 0;
	position: fixed;
	z-index: 9997;
	width: 100vw;
	animation: fadeIn 0.3s ease-out forwards;
	@media (max-width: 424px) {
		top: -10px;
		background-color: whitesmoke;
		-webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.75);
		-moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.75);
		box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.75);
	}
}
@keyframes fadeIn {
	0% {
		opacity: 0;
		transform: translateY(-74px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.container.headerIsFixed {
	transform: translateY(56px) !important;
}

.heading {
	margin-top: 0;
	padding-top: 0;
}

img.logo.aicad {
	height: 74px;
}

@media only screen and (min-width: 950px) {
	.problem {
		margin-left: -400px !important;
	}
	.menuPrinc {
		top: 8px;
		position: relative;
	}
}

@media only screen and (max-width: 950px) {
	.menuPrinc {
		height: 100vh;
		width: 100vw;
		top: 90px;
		left: 0;
		position: fixed;
		z-index: 999;
		background-color: white;
		transition: transform 1s 0.4s cubic-bezier(0, 0, 0.2, 1);
		@media (max-width: 950px) and (min-width: 575px) {
			top: 83px;
		}
	}
	.menuPrinc.showedMenu {
		transform: translateX(0%);
	}
	.menuPrinc.hiddenMenu {
		transform: translateX(100%);
	}
	.dr {
		position: static !important;
	}
}

.dropdown-f {
	position: relative;
	display: inline-block;
}

.dropdown-f-content {
	display: none;
	position: absolute;
	background-color: #007c7c;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	padding: 5px 16px;
	z-index: 1;

	a {
		border-right: none;
	}
}

.dropdown-f:hover .dropdown-f-content {
	border-right: none !important;
	display: block;

	a {
		border-right: none;
	}
}

.subtitles {
	text-align: left;
	line-height: 15pt;
	font-family: LatoWebBold, sans-serif;
	font-size: 1.4em;
	padding-left: 20px;
	margin-left: 50px;
}

.selection-sub {
	display: list-item;
	list-style-type: decimal;
	list-style-position: outside;
}

.title-selection:hover {
	color: #009090;
}

.site-navigation {
	letter-spacing: 1px;
	display: block;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	font-size: 10px;
	font-weight: bold;
	margin: 30px 0;
}

.site-navigation ul {
	background: white;
	list-style: none;
	margin: 0px;
	padding-left: 0;
}

.site-navigation li {
	background: whitesmoke;
	display: block;
	float: left;
	margin: 0 1px 0 0;
	padding: 8px;
	position: relative;
	text-decoration: none;
	text-transform: uppercase;
}

@media only screen and (max-width: 950px) {
	.site-navigation li {
		float: none !important;
	}
}

.site-navigation li {
	color: black;
}
.site-navigation li a {
	color: black;
	text-decoration: none;
	display: block;
}
.site-navigation li a:hover {
	color: #009090;
	text-decoration: none;
}

.site-navigation li:hover {
	background: white;
}

.dr {
	background: white;
	visibility: hidden;
	display: none;
	margin: 0;
	right: 0;
	max-width: 700px;
	transition: visibility 0.1s ease-in;
	border-bottom: whitesmoke solid 4px;
	margin-top: 8px;
	z-index: 999;
	position: fixed;
	left: 0 !important;
	min-width: 100%;
}

.whiter {
	background: white;
}

.dropdown {
	padding-top: 10px;
	position: absolute;
	visibility: hidden;
	z-index: 999;
}

.site-navigation ul li:hover > .dr,
.site-navigation .dr:hover {
	visibility: visible;
	display: block;
}

.site-navigation ul li:hover > .dropdown,
.site-navigation .dropdown:hover {
	visibility: visible;
}

.site-navigation ul li:hover > .whiter,
.site-navigation .whiter:hover {
	visibility: visible;
}

.site-navigation ul li ul li {
	clear: both;
	padding: 5px 0 0px 0px;
	width: 100%;
}

.site-navigation ul li ul li:hover {
	background: white;
}

.dropdown-institution {
	width: 100vw;
	text-transform: none !important;
}

@media screen and (max-width: 770px) {
	.dropdown-institution {
		width: 100%;
		left: 0;
	}
}

.dropdown-practicas {
	width: 100vw;
	text-transform: none !important;
}

@media screen and (max-width: 770px) {
	.dropdown-practicas {
		width: 100%;
		left: 0;
	}

  .hidden-sm-down {
    display: none !important;
  }
}
.dropdown-socios {
	width: 100vw;
	text-transform: none !important;
}

@media screen and (max-width: 770px) {
	.dropdown-socios {
		width: 100%;
		left: 0;
	}
}

.dropdown-language {
	width: 100vw;
	text-transform: none !important;
}

@media screen and (max-width: 770px) {
	.dropdown-language {
		width: 100%;
		left: 0;
	}
}
.dropdown-e-learning {
	max-width: 650px;
	width: 50vw;
	margin-top: 12px !important;
	text-transform: none !important;
}

@media screen and (max-width: 770px) {
	.dropdown-e-learning {
		width: 100%;
	}
}

.blue-header {
	background: #007c7c;
	width: 100%;
	height: auto;
}

.container {
	max-width: 1170px;
	height: 38px;
	padding-left: 20px;
	padding-right: 0;
	margin-right: auto;
	margin-left: auto;
}

.row {
	margin-right: 0px;
	margin-left: 0px;
}

.blue-header ul.inicio {
	height: auto;
	padding-left: 0;
	margin-bottom: 0;
	margin-top: 6px;
	letter-spacing: 1px;
}

.blue-header ul.inicio .dropdown-f:hover ul.dropdown {
	visibility: visible;
}

.blue-header ul.inicio .dropdown-f:hover ul.dropdown > li {
	border-right: none;
}

.idiomas {
	padding: 0px;
	height: auto;
	margin-bottom: 0;
	margin-top: 6px;
}

.contacto {
	width: auto;
	height: auto;
	margin-top: 5px;
}

.blue-header ul.inicio li {
	width: auto;
	float: left;
	height: auto;
	list-style: none;
	padding: 0 5px;
	border-right: 1px solid white;
}

.blue-header ul.inicio li:last-child {
	border-right: none;
}

.blue-header ul.inicio li a {
	font-size: 12px;
	font-family: Arial, Helvetica, sans-serif;
	color: #ffffff;
	text-decoration: none;
}

.blue-header ul.inicio li a:hover {
	color: #dbf0f0;
}

@media only screen and (max-width: 1024px){
  .blue-header ul.inicio li {
    width: auto;
    float: left;
    height: auto;
    list-style: none;
    padding: 0 3px;
    border-right: 1px solid white;
  }

  .blue-header ul.inicio li:last-child {
    border-right: none;
  }

  .blue-header ul.inicio li a {
    font-size: 11px;
    font-family: Arial, Helvetica, sans-serif;
    color: #ffffff;
    text-decoration: none;
  }

  .blue-header ul.inicio li a:hover {
    color: #dbf0f0;
  }
}


.idiomas li {
	width: auto;
	float: left;
	height: auto;
	list-style: none;
	padding: 0 10px;
	border-right: 1px solid white;
}

.contacto a {
	font-size: 14px;
	font-family: Arial, Helvetica, sans-serif;
	color: #dbf0f0;
}

.centrado {
  text-align: center;
}

.contacto span {
	margin-left: 5px;
}

body {
	margin: 0px;
}


.idiomas li a,
.contacto a {
	font-size: 14px;
	font-family: Arial, Helvetica, sans-serif;
	color: #dbf0f0;
	text-decoration: none;
}

.contacto a:hover {
	color: #dbf0f0;
	text-decoration: none;
}

.menu-header {
	padding-top: 3px;
	padding-right: 0px !important;
}

.header {
}


.nuevo-menus {
	width: 100%;
	background-color: white;
	height: auto;
	padding: 5px 0 10px 0;
}

.nuevo-menus .container {
	height: auto;
}

.box select {
	background-color: white;
	text-transform: uppercase;
	color: white;
	padding: 5px;
	width: 80px;
	border: none;
	-webkit-appearance: button;
	outline: none;
}

.box::before {
	position: absolute;
	top: 0;
	right: 0;
	width: 20%;
	height: 100%;
	text-align: center;
	font-size: 28px;
	color: rgba(255, 255, 255, 0.5);
	background-color: rgba(255, 255, 255, 0.1);
}

.box:hover::before {
	color: rgba(255, 255, 255, 0.6);
	background-color: rgba(255, 255, 255, 0.2);
}

.box select option {
	padding: 30px;
}

.logoAicad {
	width: 100%;
	height: auto;
}

.nuevoMenu {
	height: auto;
	position: relative;
	padding-left: 0px;
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	float: right;
	margin-top: 30px;
}

.nuevoMenu li:first-child {
	border-left: 1px solid #000000;
}

.nuevoMenu li {
	width: auto;
	float: left;
	height: 40px;
	list-style: none;
	padding: 0 9px;
	border-right: 1px solid #000000;
}

.nuevoMenu li a {
	text-decoration: none;
	font-family: 'Open sans Condensed', Arial, sans-serif;
	font-size: 12px;
	font-weight: 200;
	color: #000000;
	text-transform: uppercase;
	text-align: center;
	line-height: 20px;
	width: auto;
	float: left;
}

.menu-item > a.routeLink {
}

a.routeLink:hover {
	border-bottom: 3px solid #dddddd;
	transition: 0.1s ease;
}

img.logo.aicad {
	transition: 0.3s ease;
}

img.logo.aicad:hover {
	transform: scale(1.1);
	transition: 0.3s ease;
}

.col-12.col-sm-12.col-md-4.noPadding.plusAncho.verTodosProgramas:hover {
	opacity: 0.8;
}

.langText {
	cursor: pointer;
}

.idiomas {
	color: white;
}

.idiomas li a:hover {
	color: #e1c9ca !important;
}

.blue-header .container {
	height: auto;
	padding-bottom: 10px;
}

@media screen and (max-width: 767px) {
	.wrapper {
		overflow-x: hidden;
	}
	.header {
		margin-bottom: 0px;
	}
}

.activeLang {
	font-weight: 600;
}

.hamburglar {
	-webkit-transform: scale(1);
	transform: scale(1);
	position: absolute;
	display: block;
	width: 20px;
	height: 20px;
	top: 100px;
	right: 50px;
	background: transparent;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	z-index: 999;
	@media only screen and (min-width: 950px) {
		display: none !important;
	}
	@media only screen and (max-width: 770px) {
		top: 13px !important;
	}
}

.path-burger {
	position: absolute;
	top: 0;
	left: 0;
	height: 68px;
	width: 68px;
	-webkit-mask: url(#mask);
	mask: url(#mask);
	-webkit-mask-box-image: url('../../src/assets/img/mask.svg');
}

.animate-path {
	position: absolute;
	top: 0;
	left: 0;
	width: 68px;
	height: 68px;
}

.path-rotation {
	height: 34px;
	width: 34px;
	margin: 34px 34px 0 0;
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transform-origin: 100% 0;
	transform-origin: 100% 0;
}
.path-rotation:before {
	box-sizing: border-box;
	content: '';
	display: block;
	width: 30px;
	height: 34px;
	margin: 0 4px 0 0;
	background: #fff;
}

@-webkit-keyframes rotate-out {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	40% {
		-webkit-transform: rotate(180deg);
		transform: rotate(180deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes rotate-out {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	40% {
		-webkit-transform: rotate(180deg);
		transform: rotate(180deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@-webkit-keyframes rotate-in {
	0% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
	40% {
		-webkit-transform: rotate(180deg);
		transform: rotate(180deg);
	}
	100% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
}
@keyframes rotate-in {
	0% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
	40% {
		-webkit-transform: rotate(180deg);
		transform: rotate(180deg);
	}
	100% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
}
.hamburglar.is-open .path {
	-webkit-animation: dash-in 0.6s linear normal;
	animation: dash-in 0.6s linear normal;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}
.hamburglar.is-open .animate-path {
	-webkit-animation: rotate-in 0.6s linear normal;
	animation: rotate-in 0.6s linear normal;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

.hamburglar.is-closed .path {
	-webkit-animation: dash-out 0.6s linear normal;
	animation: dash-out 0.6s linear normal;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}
.hamburglar.is-closed .animate-path {
	-webkit-animation: rotate-out 0.6s linear normal;
	animation: rotate-out 0.6s linear normal;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

.path {
	stroke-dasharray: 240;
	stroke-dashoffset: 240;
	stroke-linejoin: round;
}

@-webkit-keyframes dash-in {
	0% {
		stroke-dashoffset: 240;
	}
	40% {
		stroke-dashoffset: 240;
	}
	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes dash-in {
	0% {
		stroke-dashoffset: 240;
	}
	40% {
		stroke-dashoffset: 240;
	}
	100% {
		stroke-dashoffset: 0;
	}
}
@-webkit-keyframes dash-out {
	0% {
		stroke-dashoffset: 0;
	}
	40% {
		stroke-dashoffset: 240;
	}
	100% {
		stroke-dashoffset: 240;
	}
}
@keyframes dash-out {
	0% {
		stroke-dashoffset: 0;
	}
	40% {
		stroke-dashoffset: 240;
	}
	100% {
		stroke-dashoffset: 240;
	}
}
.burger-icon {
	position: absolute;
	padding: 20px 16px;
	height: 68px;
	width: 68px;
}

.burger-container {
	position: relative;
	height: 28px;
	width: 36px;
}

.burger-bun-top,
.burger-bun-bot,
.burger-filling {
	position: absolute;
	display: block;
	height: 4px;
	width: 36px;
	border-radius: 2px;
	background: #009090;
}

.burger-bun-top {
	top: 0;
	-webkit-transform-origin: 34px 2px;
	transform-origin: 34px 2px;
}

.burger-bun-bot {
	bottom: 0;
	-webkit-transform-origin: 34px 2px;
	transform-origin: 34px 2px;
}

.burger-filling {
	top: 12px;
}

.burger-ring {
	position: absolute;
	top: 0;
	left: 0;
	width: 68px;
	height: 68px;
}

.svg-ring {
	width: 68px;
	height: 68px;
}

.hamburglar.is-open .burger-bun-top {
	-webkit-animation: bun-top-out 0.6s linear normal;
	animation: bun-top-out 0.6s linear normal;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}
.hamburglar.is-open .burger-bun-bot {
	-webkit-animation: bun-bot-out 0.6s linear normal;
	animation: bun-bot-out 0.6s linear normal;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

.hamburglar.is-closed .burger-bun-top {
	-webkit-animation: bun-top-in 0.6s linear normal;
	animation: bun-top-in 0.6s linear normal;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}
.hamburglar.is-closed .burger-bun-bot {
	-webkit-animation: bun-bot-in 0.6s linear normal;
	animation: bun-bot-in 0.6s linear normal;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

@-webkit-keyframes bun-top-out {
	0% {
		left: 0;
		top: 0;
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	20% {
		left: 0;
		top: 0;
		-webkit-transform: rotate(15deg);
		transform: rotate(15deg);
	}
	80% {
		left: -5px;
		top: 0;
		-webkit-transform: rotate(-60deg);
		transform: rotate(-60deg);
	}
	100% {
		left: -5px;
		top: 1px;
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}
}

@keyframes bun-top-out {
	0% {
		left: 0;
		top: 0;
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	20% {
		left: 0;
		top: 0;
		-webkit-transform: rotate(15deg);
		transform: rotate(15deg);
	}
	80% {
		left: -5px;
		top: 0;
		-webkit-transform: rotate(-60deg);
		transform: rotate(-60deg);
	}
	100% {
		left: -5px;
		top: 1px;
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}
}
@-webkit-keyframes bun-bot-out {
	0% {
		left: 0;
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	20% {
		left: 0;
		-webkit-transform: rotate(-15deg);
		transform: rotate(-15deg);
	}
	80% {
		left: -5px;
		-webkit-transform: rotate(60deg);
		transform: rotate(60deg);
	}
	100% {
		left: -5px;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}
@keyframes bun-bot-out {
	0% {
		left: 0;
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	20% {
		left: 0;
		-webkit-transform: rotate(-15deg);
		transform: rotate(-15deg);
	}
	80% {
		left: -5px;
		-webkit-transform: rotate(60deg);
		transform: rotate(60deg);
	}
	100% {
		left: -5px;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}
@-webkit-keyframes bun-top-in {
	0% {
		left: -5px;
		bot: 0;
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}
	20% {
		left: -5px;
		bot: 0;
		-webkit-transform: rotate(-60deg);
		transform: rotate(-60deg);
	}
	80% {
		left: 0;
		bot: 0;
		-webkit-transform: rotate(15deg);
		transform: rotate(15deg);
	}
	100% {
		left: 0;
		bot: 1px;
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
}
@keyframes bun-top-in {
	0% {
		left: -5px;
		bot: 0;
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}
	20% {
		left: -5px;
		bot: 0;
		-webkit-transform: rotate(-60deg);
		transform: rotate(-60deg);
	}
	80% {
		left: 0;
		bot: 0;
		-webkit-transform: rotate(15deg);
		transform: rotate(15deg);
	}
	100% {
		left: 0;
		bot: 1px;
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
}
@-webkit-keyframes bun-bot-in {
	0% {
		left: -5px;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
	}
	20% {
		left: -5px;
		bot: 0;
		-webkit-transform: rotate(60deg);
		transform: rotate(60deg);
	}
	80% {
		left: 0;
		bot: 0;
		-webkit-transform: rotate(-15deg);
		transform: rotate(-15deg);
	}
	100% {
		left: 0;
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
}
@keyframes bun-bot-in {
	0% {
		left: -5px;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
	}
	20% {
		left: -5px;
		bot: 0;
		-webkit-transform: rotate(60deg);
		transform: rotate(60deg);
	}
	80% {
		left: 0;
		bot: 0;
		-webkit-transform: rotate(-15deg);
		transform: rotate(-15deg);
	}
	100% {
		left: 0;
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
}
.hamburglar.is-open .burger-filling {
	-webkit-animation: burger-fill-out 0.6s linear normal;
	animation: burger-fill-out 0.6s linear normal;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

.hamburglar.is-closed .burger-filling {
	-webkit-animation: burger-fill-in 0.6s linear normal;
	animation: burger-fill-in 0.6s linear normal;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

@-webkit-keyframes burger-fill-in {
	0% {
		width: 0;
		left: 36px;
	}
	40% {
		width: 0;
		left: 40px;
	}
	80% {
		width: 36px;
		left: -6px;
	}
	100% {
		width: 36px;
		left: 0px;
	}
}

@keyframes burger-fill-in {
	0% {
		width: 0;
		left: 36px;
	}
	40% {
		width: 0;
		left: 40px;
	}
	80% {
		width: 36px;
		left: -6px;
	}
	100% {
		width: 36px;
		left: 0px;
	}
}
@-webkit-keyframes burger-fill-out {
	0% {
		width: 36px;
		left: 0px;
	}
	20% {
		width: 42px;
		left: -6px;
	}
	40% {
		width: 0;
		left: 40px;
	}
	100% {
		width: 0;
		left: 36px;
	}
}
@keyframes burger-fill-out {
	0% {
		width: 36px;
		left: 0px;
	}
	20% {
		width: 42px;
		left: -6px;
	}
	40% {
		width: 0;
		left: 40px;
	}
	100% {
		width: 0;
		left: 36px;
	}
}


@media only screen and (max-width: 1590px) and (min-width: 1300px){
  .blue-header .container{
      padding-bottom: 5px;
  }
}

.bg-whatsapp_icon_white2 {
    width: 20px; height: 20px;
    background: url('../assets/img/header_sprites.png') -315px -118px;
}

.bg-AICAD_LOGO2 {
    width: 65px; height: 50px;
    background: url('../assets/img/header_sprites.png') -3px -272px;
}

.bg-Conoce_Aicad2 {
    width: 150px; height: 52px;
    background: url('../assets/img/header_sprites.png') -3px -3px;
    margin-left: 30%;
	&:hover {
		width: 140px;
		opacity: 0.7;
		transition: 0.3s ease;
	}
}

.bg-Alma_Digital2 {
    width: 100px; height: 57px;
    background: url('../assets/img/header_sprites.png') -3px -209px;
    margin-left: 30%;
	&:hover {
		width: 95px;
		opacity: 0.7;
		transition: 0.3s ease;
	}
}

.bg-Franquicias2 {
    width: 180px; height: 37px;
    background: url('../assets/img/header_sprites.png') -159px -3px;
    margin-left: 30%;
	&:hover {
		width: 175px;
		opacity: 0.7;
		transition: 0.3s ease;
	}
}

.bg-Empresa2 {
    width: 150px; height: 37px;
    background: url('../assets/img/header_sprites.png') -3px -166px;
    margin-left: 30%;
	&:hover {
		width: 145px;
		opacity: 0.7;
		transition: 0.3s ease;
	}
}

.bg-Alumno2 {
    width: 150px; height: 42px;
    background: url('../assets/img/header_sprites.png') -3px -118px;
    margin-left: 25%;
	&:hover {
		width: 145px;
		opacity: 0.7;
		transition: 0.3s ease;
	}
}

.bg-Consultoria_empresarial2 {
    width: 150px; height: 51px;
    background: url('../assets/img/header_sprites.png') -3px -61px;
    margin-left: 30%;
	&:hover {
		width: 150px;
		opacity: 0.7;
		transition: 0.3s ease;
	}
}

.bg-Reclutamiento_seleccion2 {
    width: 150px; height: 42px;
    background: url('../assets/img/header_sprites.png') -159px -118px;
    margin-left: 30%;
	&:hover {
		width: 150px;
		opacity: 0.7;
		transition: 0.3s ease;
	}
}

.bg-Clubes_de_medida2 {
    width: 100px; height: 38px;
    background: url('../assets/img/header_sprites.png') -215px -209px;
    margin-left: 30%;
	&:hover {
		width: 100px;
		opacity: 0.7;
		transition: 0.3s ease;
	}
}

.bg-Certificaciones2 {
    width: 180px; height: 36px;
    background: url('../assets/img/header_sprites.png') -159px -46px;
    margin-left: 30%;
	&:hover {
		width: 175px;
		opacity: 0.7;
		transition: 0.3s ease;
	}
}

.bg-Marcas_propias2 {
    width: 100px; height: 44px;
    background: url('../assets/img/header_sprites.png') -109px -209px;
    margin-left: 30%;
	&:hover {
		width: 95px;
		opacity: 0.7;
		transition: 0.3s ease;
	}
}
</style>
